.footer-grid{
    line-height: 38px;
    position: absolute;
    bottom:0;
    background-color: rgb(217, 217, 217);
    height: 10%;
    min-height: 220px;
    vertical-align: middle;
    grid-template-columns: 3.2fr 2fr 2fr;
    grid-template-rows: 1fr 4fr;
    margin-top: 1em;
    display: grid;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 1em;
    grid-row-gap: 1em;
}
.footer-header{
    grid-row-start: 1 ;
    display: inline-block;
    color: #5899E2;
    font-size: 2em;
    font-weight: bold;
}
.footer-description{
    grid-column-start: 1 ;
    grid-row-start: 2;
}
.footer-links-1{
    grid-column-start: 2;
    width: 100%;
    margin-top: 0em;
    text-align: center;
    
}

.footer-links-2{
    grid-column-start: 3;
    width: 100%;
    text-align: center;
}


.footer-links{
    color: #5899E2 !important;
    text-decoration: underline;
    font-size: 1.2em;
    display: block;
    text-align: left;
    margin-top: 0em;
    margin-bottom: 0em;
   

}
.view-wrapper{
    position: relative;
    min-height: 100vh ;
    padding-bottom: 220px;
    
}

@media (max-width:1200px){
    .footer-links{
        font-size: 1em;
    }
    .view-wrapper{
        padding-bottom: 220px;
        
    }
    .footer-header{
        font-size: 1.7em;
    }
    .footer-grid{
        line-height: 30px;
        grid-row-gap: .5em;
    }

} 

@media (max-width:1000px){
    .footer-links{
        font-size: .9em;
    }
    .view-wrapper{
        padding-bottom: 170px;
        
    }
    .footer-header{
        font-size: 1.4em;
    }
    .footer-grid{
        line-height: 25px;
        grid-row-gap: .5em;
        min-height: 170px;
    }

} 

@media screen and (max-width:800px){
    .footer-links{
        font-size: .8em;
    }
    .view-wrapper{
        padding-bottom: 170px;
        
    }
    .footer-header{
        font-size: 1.3em;
    }
    .footer-grid{
        grid-template-columns: 2fr 2fr 2fr;
        line-height: 25px;
        grid-row-gap: .5em;
        min-height: 170px;
    }


} 

@media screen and (max-width:600px){
    .footer-links{
        font-size: .7em;
    }
    .view-wrapper{
        padding-bottom: 150px;
        
    }
    .footer-header{
        font-size: 1.0em;
    }
    .footer-grid{
        grid-template-columns: 2fr 2fr 2fr;
        line-height: 20px;
        grid-row-gap: 0em;
        min-height: 150px;
    }


} 

@media screen and (max-width:480px){
    .footer-links{
        font-size: .6em;
    }
    .view-wrapper{
        padding-bottom: 125px;
        
    }
    .footer-header{
        font-size: .8em;
    }
    .footer-grid{
        grid-template-columns: 2fr 2fr 2fr;
        line-height: 20px;
        grid-row-gap: 0em;
        min-height: 125px;
        margin-top: 0;
        padding-left: 3%;
        padding-right: 3%;
    }


} 

@media screen and (max-width:360px){
    .footer-links{
        font-size: .5em;
    }
    .view-wrapper{
        padding-bottom: 125px;
        
    }
    .footer-header{
        font-size: .65em;
    }
    .footer-grid{
        grid-template-columns: 2fr 2fr 2fr;
        grid-template-rows: 1fr 5fr;
        line-height: 15px;
        grid-row-gap: 0em;
        min-height: 125px;
        margin-top: 0;
        padding-left: 3%;
        padding-right: 3%;
    }


} 