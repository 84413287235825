.topic-wrapper{
    width: 100%;
    display: flex;
    margin-top: 1em;
    height: 65px;
    border-radius: 15px;
    color: white;

}
.topic-left{
    justify-content: flex-start;
    flex-direction: row;
    vertical-align: middle;
    margin: auto auto;
    display: flex;
    overflow: hidden;
    margin-left: 1%;
}
.topic-left .icon-placeholder{
    width: 50px;
}
.topic-right{
    justify-self: flex-end;
    justify-content: flex-start;
    flex-direction: row;
    vertical-align: middle;
    margin: auto auto;
    width: 175px;
    white-space: nowrap;
    align-items: flex-start;
    display: flex;


}
.topic-icon{
    width:  40px !important;
    min-width: 40px;
    display: inline-block;
    height: fit-content;
    padding: auto auto;
}
.topic-icon-direct{
    font-size: 5em;
    height: 100%;
    width: fit-content;

}
.topic-chevron{
    font-size: 2em;
    display: inline-flex;
    align-items: center;
    // font-weight: bold;
}
.topic-title{
    width:  fit-content;
    display: inline-flex;
    font-family: "OpenSans";
    // line-height: ;
    align-items: center;
    font-size: 1.5em;
    margin-left: 10px;
    overflow: hidden;
}
.topic-expand{
    width:  fit-content;
    display: inline-block;
}
.edit-topic-wrapper{
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    gap: 5%;

}
.drag-over{

    background-color: grey;
}

.select-icon-dropdown{
    width: fit-content; 

}
.select-topic-dropdown{

}
.select-dropdown{
    width: 50px;

}
.topic-color-picker{
    width: 275px !important;

  }
  .color-picker-wrapper{
      display: flex;
      gap: 12px;
      margin-right: 1em;

  }
  .color-picker-text{
    font-size: 1.5em;
    color: rgb(54, 97, 158);
    font-weight: 400;
  }
  .edit-topic-submit{
      margin-right: auto;
      text-align: end;
    //   width: fit-content;
      button{
          background-color: #5899E2  ! important;
          border-color: #5899E2  ! important;
            text-transform: none;
            font-size: 1.5em;
            font-family:'Raleway';
            color: white;
            border-color: #FCAB10;
            border-radius: 8px;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: bold;
            white-space: nowrap;
            width: fit-content;
        }
      
  }
.select-icon{
font-size: 1.5em;
height: fit-content !important;
border-bottom-style: solid;
border-bottom-color: #5899E2  ! important;
}
.select-icon:hover{
    width: fit-content;
    outline: none ! important;
    background-color: #5899E2 ! important;
    border-bottom-style: solid;
    border-bottom-color: #FCAB10 ! important;

}
.no-icon{
    font-size: 1.5em !important;
    align-self: center;
    justify-content: center;
    align-content: center;


}
.no-icon-wrapper{
    width: 80%;
    text-align: center;
}
.no-icon::after{
    font-size: 1.7em;
    margin-left: calc(max(25%  )  / 2 ) !important;
}
#select-topic-input::after{
margin-left: calc(max(50% )  / 2 + 12px);
width: fit-content;
align-self: center;

}
#select-topic-input{
    
    overflow: hidden;
    margin: auto 0;
    margin-right: 0em;

    height: 50px;
    min-width: 150px;
    width: 15vw;
    padding: 5px 5px;
    border-radius: 10px;
    border-bottom: solid;
    text-align: right;
    vertical-align: middle;
    background: transparent;
    background-color: #5899E2 ;
    display: flex;
    justify-content: flex-end;
    
    


    border: 1px solid #dfdfdf;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    padding: 6px 10px;
    color: white;
    font-size: 2.5em;
}
.edit-topic-link{
    text-decoration: underline;
    color: #5899E2;
    width: fit-content;
    margin-left: 2.5%;
    margin-top: 0;
    font-family: "raleway";
    font-weight: 800;
    font-size: 1.2em;
}
.set-list{
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
}
.select-add-topic::placeholder{
    color: white;
}
.topic-input-error{
    align-self: center;
    font-size: 1.5em;
    margin-left: 5%;

}
.delete-topic{
    width: fit-content;
    align-self: center;
    margin-right: 3%;


}
.trash-topic-icon{
    font-size: 22pt;
  }

.select-add-topic{
        border-style: none;
        // border-bottom-style: solid;
        // border-bottom-color: #FCAB10 ;
        background-color: #5899E2 ;
        color: white;
        width: 50%;
        margin-left: 8%;
        font-size: 1.4em;
        height: fit-content;
        align-self: center;
        font-family: "OpenSans";
        font-style: italic;

}


@media (max-width:1350px){

}
@media (max-width:1200px){
    .topic-color-picker{
        width: 200px !important;
      }
      .edit-topic-submit .button-main{
        font-size: 1.4em;
      }
      .topic-wrapper .topic-input-error{
        font-size: 1.3em;
      }
      .topic-wrapper .select-add-topic{
        font-size: 1.3em;
        width: 75%;
      }
}
@media (max-width:1000px){
    .trash-topic-icon{
        font-size: 20pt;
      }
    .edit-topic-submit .button-main{
        font-size: 1.4em;
      }
      .topic-wrapper .topic-input-error{
        font-size: 1.05em;
      }
      .topic-wrapper .select-add-topic{
        font-size: 1.1em;
        width: 80%;
        margin-left: 4%;
      }

    .topic-color-picker{
        width: 200px !important;
      }
      .color-picker-wrapper{
          width: 250px;
      }
      .edit-topic-submit .button-main{
        font-size: 1.1em;
      }
      .color-picker-text{
          font-size: 1.3em;
          font-weight: 500;
      }
      .no-icon{
          font-size: 1.1em ! important;
          align-self: center;
          
      }
      .edit-topic-wrapper #select-topic-input{
          min-width: 0px !important;
      }
      .edit-topic-wrapper{
        gap: 3%;
      }
      .no-icon-wrapper{
        align-self: center;
      }


    .topic-title{
        font-size: 1.2em;
    }
    .topic-chevron{
        font-size: 1.7em;
    }
    .topic-wrapper{
        height: 50px;
    }
    .topic-icon{
        width: 30px ! important;
        min-width: 30px ! important;
    }
    .icon-placeholder{
        width: 30px ! important;
        min-width: 30px ! important;
    }
  


}

@media (max-width:760px){
    .trash-topic-icon{
        font-size: 16pt;
      }

      .topic-wrapper .topic-input-error{
        font-size: .8em;
      }
      .topic-wrapper .select-add-topic{
        font-size: .9em;
        width: 80%;
        margin-left: 4%;
      }
      #select-topic-input::after{
          font-size: .8em;
          margin-left: calc(max(30% ) / 2 + 12px);
      }

      
    .edit-topic-link{
        font-size: 1.0em;
    }
    .topic-color-picker{
        width: 165px !important;
      }
      .color-picker-wrapper{
          width: fit-content;
          margin-right: 0;

      }
      .edit-topic-submit .button-main{
        font-size: .8em;
      }
      .color-picker-text{
          font-size: .8em;
          font-weight: 500;
      }
      .no-icon{
          font-size: .8em ! important;
          align-self: center;
          
      }
      .edit-topic-wrapper #select-topic-input{
          min-width: 0px !important;
          padding: 5px 5px;
      }
      .edit-topic-wrapper{
        gap: 2%;
      }
      .no-icon-wrapper{
        align-self: center;
      }


    .topic-title{
        font-size: 1.0em;
    }
    .topic-chevron{
        font-size: 1.4em;
    }
    .topic-wrapper{
        height: 50px;
    }
    .topic-icon{
        width: 25px ! important;
        min-width: 25px ! important;
    }
    .icon-placeholder{
        width: 25px ! important;
        min-width: 25px ! important;
    }
    .topic-right{
        width: 150px;
    }
}

  @media (max-width:600px){
    .trash-topic-icon{
        font-size: 13pt;
      }

      .topic-wrapper .topic-input-error{
        font-size: .7em;
      }
      .topic-wrapper .select-add-topic{
        font-size: .75em;
        width: 80%;
        margin-left: 3%;
      }
      #select-topic-input::after{
          font-size: .6em;
          margin-left: calc(max(30% ) / 2 + 12px);
      }


    .edit-topic-link{
        font-size: .8em;
    }
    .topic-color-picker{
 
        width: 130px !important;
      }
      .color-picker-wrapper{
          width: fit-content;
          margin-right: 0;

      }
      .edit-topic-submit .button-main{
        font-size: .8em;
        padding: 6.5px 5px;
      }
      .topic-color-picker div{
          margin-right: 10px !important;
          margin-bottom: 10px !important;
      }
      .color-picker-text{
          font-size: .8em;
          font-weight: 500;
          width: 40px;
      }
      .no-icon{
          font-size: .8em ! important;
          align-self: center;
          
      }
      .edit-topic-wrapper #select-topic-input{
          min-width: 0px !important;

      }
      .edit-topic-wrapper{
        gap: 2%;
      }
      .no-icon-wrapper{
        align-self: center;
      }


    .topic-title{
        font-size: .8em;
        line-height: 2em;
    }
    .topic-chevron{
        font-size: 1.3em;
    }
    .topic-wrapper{
        height: 50px;
    }
    .topic-icon{
        width: 25px ! important;
        min-width: 25px ! important;
    }
    .icon-placeholder{
        width: 25px ! important;
        min-width: 25px ! important;
    }
    .topic-right{
        width: 130px;
    }

  }

  @media (max-width:480px){
    .trash-topic-icon{
        font-size: 11pt;
      }

      .topic-wrapper .topic-input-error{
        font-size: .5em;
      }
      .topic-wrapper .select-add-topic{
        font-size: .65em;
        width: 80%;
        margin-left: 3%;
      }
      #select-topic-input::after{
          font-size: .5em;
          margin-left: calc(max(15% ) / 2 + 12px);
      }
    .edit-topic-link{
        font-size: .7em;
    }
    .topic-color-picker{
 
        width: 100px !important;
        margin-top: 0em;
      }
      .color-picker-wrapper{
          width: fit-content;
          margin-right: 0;

      }
      .edit-topic-submit .button-main{
        font-size: .7em;
        padding: 4.5px 3px;
        width: 60px !important;
        height: 50px;
        word-wrap: break-word !important;
        overflow-wrap: break-word;
        overflow-x: hidden;
        white-space: normal;
        margin-top: 0px;
      }
      .topic-color-picker div{
          margin-right: 8px !important;
          margin-bottom: 8px !important;
      }
      .select-icon-dropdown .select-dropdown{
          width: 25px;
          min-width: 100px;
      }
      .color-picker-text{
          font-size: .8em;
          font-weight: 500;
          width: 40px;
      }
      .no-icon{
          font-size: .6em ! important;
          align-self: center;
          
      }
      .select-icon-dropdown{
          align-self: center;
          height: fit-content;
      }
      .edit-topic-wrapper #select-topic-input{
          min-width: 0px !important;
          height: 30px;


      }
      .edit-topic-wrapper{
        gap: 2%;
      }
      .no-icon-wrapper{
        align-self: center;
      }



    .topic-title{
        font-size: .6em;
        line-height: 3.0em;
    }
    .topic-right{
        width: 90px;
    }
    .topic-chevron{
        font-size: .9em;
    }
    .topic-wrapper{
        height: 50px;
    }
    .topic-icon{
        width: 22px ! important;
        min-width: 22px ! important;
    }
    .icon-placeholder{
        width: 22px ! important;
        min-width: 22px ! important;
    }

  }

  @media (max-width:360px){

    .trash-topic-icon{
        font-size: 10pt;
      }

      .topic-wrapper .topic-input-error{
        font-size: .45em;
      }
      .topic-wrapper .select-add-topic{
        font-size: .55em;
        width: 80%;
        margin-left: 3%;
      }
      #select-topic-input::after{
          font-size: .4em;
          margin-left: calc(max(10% ) / 2 + 12px);
      }

    .edit-topic-link{
        font-size: .65em;
    }
    .topic-color-picker{
 
        width: 80px !important;
        margin-top: 0em;
      }
      .color-picker-wrapper{
          width: fit-content;
          margin-right: 0;

      }
      .edit-topic-submit .button-main{
        font-size: .60em;
        padding: 3px 2.2px;
        width: 50px !important;
        height: 40px;
        word-wrap: break-word !important;
        overflow-wrap: break-word;
        overflow-x: hidden;
        white-space: normal;
        margin-top: 0px;
      }
      .topic-color-picker span div{
          margin-right: 4px !important;
          margin-bottom: 4px !important;
      }
      .topic-color-picker span{
          padding: 0 0 !important;
      }
      .select-icon-dropdown .select-dropdown{
          width: 25px;
          min-width: 100px;
      }
      .color-picker-text{
          font-size: .65em;
          font-weight: 500;
          width: 30px;
      }
      .no-icon{
          font-size: .5em ! important;
          align-self: center;
          
      }
      .select-icon-dropdown{
          align-self: center;
          height: fit-content;
      }
      .edit-topic-wrapper #select-topic-input{
          min-width: 0px !important;
          height: 30px;


      }
      .edit-topic-wrapper{
        gap: 2%;
      }
      .no-icon-wrapper{
        align-self: center;
      }
  }


