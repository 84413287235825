.generator-instructions{
    font-size: 1.5em;
}

.generator-subtext{

}
.drag-and-drop{
    text-align: center;

}
.drag-wrapper{
    text-align: center;
    margin: auto auto;
    margin-bottom: 100px;

}

.parser-setname-wrapper{
    padding-left: 7.5em;
}
.dragging-wrapper{

}
.dragged
{
    border-color: #5899E2 ! important;
    color: #5899E2 ! important;
}

.no-drag-wrapper{

}

.generator-setname-wrapper{
    justify-content: left;
    width: 100%;
    display: flex;
    height: fit-content;
    // margin-bottom: 1em;
    margin: auto auto;
    vertical-align: middle;
    padding-left: 8%;
}

.potential-card-body{
    padding-bottom: 10em;
    margin-top: 3em;
}

.parser-submit-button{
    font-size: 1.5em;
    text-transform: none;
    font-family: 'Glacial';
}
.black-text::placeholder{
    color: black;
}
.drag-body{
    margin: auto auto;
    border-style: dotted;
    width: 80%;
    max-height: 400px;
    max-width: 700px;
    height: 500px;
    margin-top: 4em;
    border-radius: .4em;
    vertical-align: middle;
    color: rgba($color: #757575, $alpha: .8);
}
.drag-and-drop-icon{
    color: rgba($color: #757575, $alpha: .4);
    font-size: 15em;

    padding: auto auto;

    height: 100%;
    

}
@media (max-width:760px){
    .generator-instructions{
        font-size: 1em;
    }
    .generator-subtext{
        font-size: .8em;
    }
    .drag-body{
        margin-top: 2em;
    }
}

@media (max-width:600px){
    .generator-instructions{
        font-size: .9em;
    }
    .generator-subtext{
        font-size: .7em;
    }
    .drag-body{
        margin-top: 2em;
    }
    .drag-and-drop-icon{
        font-size: 10em;
    }
}

@media (max-width:480px){
    .generator-instructions{
        font-size: .7em;
    }
    .generator-subtext{
        font-size: .5em;
    }
    .drag-body{
        margin-top: 1em;
    }
    .drag-and-drop-icon{
        font-size: 10em;
    }
}

@media (max-width:360px){
    .generator-instructions{
        font-size: .6em;
    }
    .generator-subtext{
        font-size: .45em;
    }
    .drag-body{
        margin-top: 1em;
    }
    .drag-and-drop-icon{
        font-size: 8em;
    }
}