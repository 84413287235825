.cards{
    // margin-top: 50px;
    text-align: center;
    
    grid-column-start: 2;
    grid-column-end:2;
    grid-row-start: 2;

}

  
.card-counter{
    
    display: block;
    width: fit-content;
    
    
    text-align: center;
    margin: 30px auto;
    font-size: 16pt;
}
.arrow{
    
  
    width:20px;
    float: left;
}
.CircularProgressbar{
    min-height: 25px ! important;
    min-width: 25px ! important;
}



.delete-popup-header{
    margin-top: 2em;
    width: 80%;
    text-align: center;
    // padding: 0em 2em;
    margin: 1.5em auto;
    font-size: 1.75em;
}
.popup-button-wrapper{
    text-align: center;
  

}
.delete-popup{
    text-align: center;
    padding: auto auto;
    vertical-align: middle;
    height: 100%;
    
}

.popup-button{
    background-color: rgb(248, 248 , 248);
    font-size: 1em;
    margin-top: .25em;
    font-weight: normal;
    text-transform: none;

    border-color: grey;
    border-radius: 1em;
    color: black;
    border-style: solid;
    margin-left: 1em;
    max-width: 9em;
    min-width: 9em;
    height: 4em;
    text-align: center;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    
}


.icon-active{
    color: green
}
.index{   
    width:50px;
    float: left;
}


.prog{
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
    vertical-align: middle;
    margin: auto 0;
    // width: fit-content;


}
.hideIcon{
    flex-basis: 5%;
    font-size: 16pt;
}





.close-icon{
    float: left;
    color: red;
    font-size: 20pt;
    width:50px;
}

.card-table-wrapper{
    
    grid-column-start: 2;
    grid-column-end:2;
    grid-row-start: 3;
    grid-row-end: 3;
    padding-top: 60px;
    margin-bottom: 100px;
}
.side-bar-wrapper{
    grid-column-start: 1;
    grid-column-end:1;
    grid-row-start: 1;
    grid-row-end: 4;
    background-color: rgb(57, 87, 128);
    width: 80%;

}



.card-grid{
    display: grid;
    grid-template-columns: 3fr 5fr 3fr;
    grid-template-rows: minmax(100px , 1fr) minmax(400px , auto) 4fr;
    width: 100%;
    height: 100vh;
    background-color: #ebedf2;
    // min-height: 1000px;

    // height: 100%;
}

@media (max-height: 1000px){
    .card-grid{
    min-height: 1000px;
    }

}
@media (max-height: 800px){
    .card-grid{
    min-height: 900px;
    }

}
@media (max-height: 700px){
    .card-grid{
    min-height: 750px;
    grid-template-rows: 1fr 350px 4fr;
    }

}
@media (max-height: 500px){
    .card-grid{
    min-height: 575px;
    }
    .card-grid{
        min-height: 750px;
        grid-template-rows: 1fr 200px 4fr;
        }

}
@media (max-height: 400px){
    .card-grid{
    min-height: 450px;
    }

}


.card{        
    &__side {
        padding: auto auto;
        text-align: center;
        justify-content: center;
        vertical-align: middle;
        margin: auto auto;

        
        left: 0;
        right: 0;
        top:0;
        bottom:0;
        // position: relative;
    }

    &--front {
        position: absolute; 
      }
      
      &--back {
        display: flex;
        color: #FFF;
        vertical-align: middle;
        transform: rotateY(180deg);
        
        &-1 {
        //   background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);      
        }
    }

    &__description {
        font-size: 2.0em;
            }

}  


@media (max-width: 1000px){

    .cards{

    }
    .card-counter{
        position: relative;
        bottom: 2em;
    }
    .card{        
        &__side {
            width: 320px;
            height: 250px;
            left: 0;
            right: 0;
            top:0;
            bottom:0;
            // position: relative;
        }

        

        &__description {
            font-size: 1.3em;
                }

    }  
    
}

@media (max-width: 760px){
    .card-table-wrapper{
        padding-top: 10px;
    }
    .card-table{
        .card-table-header {
            font-size: .8em;
        }
        li {
            padding: 25px 15px;
            
        }
        .card-table-row{
            font-size: .8em;
        }
    }
    .card-grid{}

    .card-counter{
        position: relative;
        bottom: 3em;
        font-size: 1em;
    }
    .card{

        font-size: .5em;
        &__side {
            
            padding: auto auto;
            text-align: center;
            justify-content: center;
            vertical-align: middle;
            margin: auto auto;
            width: 250px;
            height: 215px;
            left: 0;
            right: 0;
            top:0;
            bottom:0;
            // position: relative;
        }

        &--front {
            position: absolute; 
          }
          
          &--back {
            display: flex;
            color: #FFF;
            vertical-align: middle;
            transform: rotateY(180deg);
            
            &-1 {
            //   background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);      
            }
        }

        &__description {
            font-size: 15pt;
                }

    }  
    
}


@media (max-width: 600px){
    .card-table{
        .card-table-header {
            font-size: .6em;
            vertical-align: middle;
            padding: auto auto;
        }
        li {
            padding: 25px 15px;
            
        }
        .card-table-row{
            font-size: .55em;
        }
    }

    .card-grid{}
    .cards{

    }
    .card-counter{

        bottom: 3em;
        font-size: 1em;
    }
    .card{

        font-size: .5em;
        &__side {
            
            padding: auto auto;
            text-align: center;
            justify-content: center;
            vertical-align: middle;
            margin: auto auto;
            width: 220px;
            height: 180px;
            left: 0;
            right: 0;
            top:0;
            bottom:0;
            // position: relative;
        }

        &--front {
            position: absolute; 
          }
          
          &--back {
            display: flex;
            color: #FFF;
            vertical-align: middle;
            transform: rotateY(180deg);
            
            &-1 {
            //   background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);      
            }
        }
        &__description {
            font-size: 12pt ;
                    
        
                }

    }
   
    
}

@media (max-width: 460px){
    .view-set-popup-wrapper{
        width: 300px !important;
    }
    .delete-popup-header{
        margin-top: 2em;
        width: 80%;
        text-align: center;
 
        margin: 1.5em auto;
        font-size: 1em;
    }
    .popup-button-wrapper{
        text-align: center;
      
    
    }
    .delete-popup{
        text-align: center;
        padding: auto auto;
        vertical-align: middle;
        height: 100%;
        // width: 275px;
        
    }
    
    .popup-button{
        background-color: rgb(248, 248 , 248);
        font-size: .75em;
 

        margin-left: 1em;
        max-width: 9em;
        min-width: 9em;
        height: 4em;
        text-align: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-right: 20px;
        
    }
    .card-counter{
        position: relative;
        bottom: 3em;
        font-size: 1em;
    }
    .card{

        font-size: .3em;
        &__side {
            
            padding: auto auto;
            text-align: center;
            justify-content: center;
            vertical-align: middle;
            margin: auto auto;
            width: 170px;
            height: 130px;
            left: 0;
            right: 0;
            top:0;
            bottom:0;
            // position: relative;
        }

        &--front {
            position: absolute; 
          }
    &__description {
    font-size: 11pt;
    }
        }

    }

@media (max-width: 360px){

    .card-table{
        .card-table-header {
            font-size: .5em;
            vertical-align: middle;
            padding: auto auto;
        }
        li {
            padding: 15px 15px;
            
        }
        .card-table-row{
            font-size: .45em;
        }
    }


    .card-grid{}

    .card-counter{
        position: relative;
        bottom: 0;
        font-size: 1em;
    }
    .card{

        font-size: .17em;
        &__side {
            
            padding: auto auto;
            text-align: center;
            justify-content: center;
            vertical-align: middle;
            margin: auto auto;
            width: 140px;
            height: 110px;
            left: 0;
            right: 0;
            top:0;
            bottom:0;
            // position: relative;
        }

        &--front {
            position: absolute; 
            }
        
        &__description {
        font-size: 9pt;
                

            }

    }
}
        


    
    


