.quiz-option{
    // flex:auto;
    
    color: black;
    background-color:  white;
    min-width: 200px;
    max-width: 300px;
    text-align: center;
    font-family: "Glacial";
    font-size: 20pt;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: 5px;
    margin: 1em auto;
    grid-column-start: auto;
    grid-row-start: auto;
    word-wrap: break-word;
    box-shadow: .5em .5em 2em rgba(0,0,0,.35);
    padding-left: 5px;
    padding-right:5px;
}
.quiz-div{

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    padding-top: 1em;
    width:100%;
    text-align: center;
    margin: 0 auto;
    font-size: 16pt;
    grid-column-gap: 1em;
    

}
.quiz-correct{
    border-style: solid;
    border-color: green;
    border-width: 1px;
    background-color: #18b82a;
}
.quiz-incorrect{
    border-style: solid;
    border-color: red;
    border-width: 1px;
    background-color: #fca192;
}
