.side-bar-container{
    border-right-style: solid ;
    border-right-color:#FCAB10;
    width: 100%;
    height: 100%;
    padding-top: 2em;
}
.options-grid{
    display: flex;
    height: fit-content;
    margin-top: 20px;
    padding-left: 7%;
    // justify-content: center;

    // grid-template-rows: 1fr;

}

.options-grid-icons{
    display: flex;
    height: fit-content;
    margin-top: 5px;
    // padding-left: 7%;
    width: 90%;
    justify-content: flex-end;
    // align-items: right;
    padding: auto auto;

    // grid-template-rows: 1fr;

}

.set-name-side{
    justify-content: flex-start;
    float: left;
    color: white;
    font-family: "Glacial";
    // width: fit-content;
    
    height: fit-content;
    max-width: 90%;
    word-wrap: break-word;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 24pt;
    // margin-left: 50px;
    // margin-top: 50px;
    border-bottom: solid;
    border-bottom-width: 3px;
    border-bottom-color: #FCAB10;
    background-color: transparent;
}
.set-icon{
    width:fit-content;
    text-align: center;
}

.check-icon{
    flex:auto;
    color: #00CFC1;
    font-size: 20pt;
    margin-top: 0px; 
    color: white;
}

.delete-set-icon{

    width: fit-content;
    margin-left: .75em;
    margin-top: .2em;
    font-size: 18pt ! important;
    color: white;

}

.edit-set-icon{
    // margin-top: .2em;
    width: fit-content;
    // font-size: 15pt ! important;
    // width: auto;
    color: white;
    vertical-align: middle;
    padding-top: auto;
    margin: auto 0;
}

.study-icon{

    flex:auto;
    margin-top: 5px;
    // width: fit-content;
    // padding-left: 1.5em;
    font-size: 18pt;
    color: white;
}

.sets-option-button{
    flex:auto;
    color: white;
    background-color:  #FCAB10;
    width: 80%;
    text-align: center;
    font-family: "Glacial";
    font-size: 20pt;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: 5px;
    margin: 1em auto;


}
.side-bar-charts-text{
    color: white;
    font-family: "Glacial";
    font-size: 17pt;
    width: 100%;
    text-align: center;
    padding: 0 1.5em;
    padding-top: 1em;
}
.side-bar-charts{
    // min-height: 00px;
    margin-bottom: 50px;
}
.side-bar-prog{
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 1em 0;
    // width: fit-content;


}

.CircularProgressbar-trail{
    stroke: white ! important;
}
.green-text{
    display: inline;
    color: #20c294;
}

@media (max-width: 1400px){

}

@media (max-width: 1200px){

    .set-name-side{
        font-size: 12pt !important;
        padding-bottom: .2em;
        height: fit-content;
        margin-bottom: 0;
        }
    .side-bar-charts-text{
        font-size: 14pt;
        padding: 0 1.5em;
        padding-top: 1em;
    }
    .sets-option-button{
        width: 80%;

        font-size: 16pt;
        padding-top: .5em;
        padding-bottom: .5em;
        border-radius: 5px;
        margin: 1em auto;
    
    
    }

}

@media (max-width: 1000px){
    .side-bar-charts-text{
        font-size: 12pt;
        padding: 0 1.5em;
        padding-top: 1em;
    }

    .prog-bar-direct{
        // width: 50px ! important;
        // font-size: 12pt ! important;
        margin: auto auto;
    }
    .sets-option-button{
        width: 70%;

        font-size: 14pt;
        padding-top: .4em;
        padding-bottom: .4em;
        border-radius: 5px;
        margin: 1em auto;
    
    
    }

.set-name-side{
    font-size: 12pt !important;
    padding-bottom: .2em;
    padding-right: 5px;
    padding-left: 5px;
    height: fit-content;
    margin-bottom: 0;
    }
    .delete-set-icon{
        margin-left: .2em;
        float: left;
        justify-content: flex-start;
        margin-top: 8px;
        padding-left: .5em;
        font-size: 10pt ! important;
    }
    
    .edit-set-icon{
        float: right;
        justify-content: flex-start;
        margin-top: 0em;
        padding-left: 1em;
        font-size: 18pt ! important;
        width: auto;

    }
    .pencil-icon-direct{
        font-size: 10pt !important;
    }
    .delete-icon-direct{
        font-size: 12pt !important;
    }
    .study-icon{
        margin-top: 5px;
        padding-left: 1.5em;
        font-size: 15pt;
    }
   

}

    @media (max-width: 760px){
        .sets-option-button{
            width: 70%;
    
            font-size: 12pt;
            padding-top: .4em;
            padding-bottom: .4em;
            border-radius: 5px;
            margin: 1em auto;
        
        
        }

        .set-name-side{
            font-size: 10pt !important;
            padding-bottom: .2em;
            height: fit-content;
            margin-bottom: 0;
            }
        .delete-set-icon{
            margin-left: .2em;
            float: left;
            justify-content: flex-start;
            margin-top: 8px;
            padding-left: .5em;
            font-size: 10pt ! important;
        }
        
        .edit-set-icon{
            float: right;
            justify-content: flex-start;
            margin-top: 0em;
            padding-left: 1em;
            font-size: 18pt ! important;
            width: auto;
    
        }
        .pencil-icon-direct{
            font-size: 10pt !important;
        }
        .delete-icon-direct{
            font-size: 12pt !important;
        }
        
        .study-icon{
            margin-top: 5px;
            padding-left: 1.5em;
            font-size: 15pt;
        }
        .check-icon{
        }
        .close-icon{
        }
        .set-name{
            max-width: 30%;
            font-size: 1.2em;
            padding-bottom: .2em;
            height: fit-content;
            margin-bottom: 0;
        }
        .side-bar-charts-text{
            font-size: 10pt;
            padding: 0 1.5em;
            padding-top: 1em;
        }
    }

    @media (max-width: 600px){
        .sets-option-button{
            width: 70%;
    
            font-size: 10pt;
            padding-top: .4em;
            padding-bottom: .4em;
            border-radius: 5px;
            margin: 1em auto;
        
        
        }
        .delete-set-icon{
            float: left;
            justify-content: flex-start;
            margin-top: .05em;
            padding-left: .3em;
            font-size: 14pt ! important;
        }
        
        .edit-set-icon{
            float: right;
            justify-content: flex-start;
            margin-top: -.19em;
            padding-left: .3em;
            font-size: 18pt ! important;
            width: auto;
        }
        .pencil-icon-direct{
            font-size: 10pt !important;
        }
        .delete-icon-direct{
            font-size: 12pt !important;
        }
        
        .study-icon{
            margin-top: 3px;
            padding-left: 1.5em;
            font-size: 12pt;
        }
        .side-bar-charts-text{
            font-size: 8pt;
            padding: 0 1.5em;
            padding-top: 1em;
        }
        

    }

    
@media (max-width: 460px){
    .delete-set-icon{
        float: left;
        justify-content: flex-start;
        margin-top: 6px;
        padding-left: .3em;
        font-size: 10pt ! important;
    }
    
    .edit-set-icon{
        float: right;
        justify-content: flex-start;
        margin-top: -.19em;
        padding-left: 1em;
        font-size: 18pt ! important;
        width: auto;
    }
    .pencil-icon-direct{
        font-size: 8pt !important;
    }
    .delete-icon-direct{
        font-size: 10pt !important;
    }
    
    .study-icon{
        margin-top: 3px;
        padding-left: 1.5em;
        font-size: 12pt;
    }
        
 
    .check-icon{
    }
    .close-icon{
    }
    .set-name{
        font-size: .6em;
        padding-bottom: .2em;
        height: fit-content;
        margin-bottom: 0;
        margin-left: 3em;
        margin-top: .3em;
    }
}

@media (max-width: 360px){
    .delete-set-icon{
        float: left;
        justify-content: flex-start;
        margin-top: .05em;
        padding-left: 1em;
        font-size: 14pt ! important;
    }
    
    .edit-set-icon{
        float: right;
        justify-content: flex-start;
        margin-top: -.19em;
        padding-left: 1em;
        font-size: 18pt ! important;
        width: auto;
    }
    .pencil-icon-direct{
        font-size: 12pt !important;
    }
    .delete-icon-direct{
        font-size: 14pt !important;
    }
    
    .study-icon{
        margin-top: 3px;
        padding-left: 1.5em;
        font-size: 12pt;
    }
    .set-name{
        font-size: .6em;
        padding-bottom: .2em;
        height: fit-content;
        margin-bottom: 0;
        margin-left: 3em;
        margin-top: .3em;
    }
}