@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;


*, *::after, *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// body {
//   font-family: "Open Sans", sans-serif;
//   color: white;
// }




.card{
  background-color: #ebedf2;
  width: 100%;
  height: 100%;
  margin: auto auto;
  vertical-align: middle;
  grid-column-start: 1;
  max-width: 375px;
  max-height: 275px;
  border: none !important;

  margin-bottom: 50px;
  
  // height: calc((100vw - 20px) * .7);
  
  perspective: 1000em;
  
  &__side {
    position: absolute;
    vertical-align: middle;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    backface-visibility: hidden;
    transition: all .6s ease;
    box-shadow: 1em 1em 2em rgba(0,0,0,.2);
    
    &--front {
      display: flex;
      vertical-align: middle;
      background-color: #FFF; 
    }
    
    &--back {
      display: flex;
      color: #FFF;
      vertical-align: middle;
      transform: rotateY(180deg);
      
      &-1 {
        background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);;        
      }
      

    }


  }
  
  &:hover &__side--front {
    transform: rotateY(-180deg);
  }
  
  &:hover &__side--back {
    transform: rotate(0);
  }

  &__description {
    
  
    margin: auto auto;
    text-justify: center;
    vertical-align: middle;
    // text-transform: uppercase;
    font-size: 1.7em;
    word-wrap: break-word;
    // padding: auto au;
    

  }
  

}