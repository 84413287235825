.topic-button-plus {
  font-family: "Glacial" ;
    float:left;
    width:100px;
    // height:40px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    color: white;
    vertical-align: top;
    text-align: center;
    font-size: 20px ;
    margin-top: 20px;
    padding-right: 13px ;
    padding-left: 15px ;
    padding-bottom: 5px;
    padding-top: 5px;
    margin: auto auto;
    border-width: 2px;
    margin-right: 1em;
   
    background-position:center;
    border-radius: 50px;
    background-repeat:no-repeat;
    text-transform: capitalize;
  }


  .settopic-input
  {
    text-align: left;
    margin-left: 0 ! important;
    margin-bottom: 1em ! important;
    padding: 8px 10px ! important;
    border-bottom: solid;
    border-bottom-color:#FCAB10 ;
    // background-color: white;
  }

  
  @media (max-width:1000px){

    .settopic-input
    {
      margin-left: 0 ! important;
      margin-bottom: 1em ! important;
      padding: 8px 10px ! important;
    }

  }
  @media (max-width:768px){
    .topic-button-plus{
      width: 90px;
      font-size: 1em;
      padding-right: 15px;
    }
  }
  @media (max-width:600px){
    .topic-button-plus{
      width: 85px;
      font-size: .9em;
      padding-right: 13px;
      padding-left: 13px;
    }
  }
  @media  (max-width:480px){
    // .topic-color-picker{
    //   margin-top: 1em;
    // }
    // span{
    //   padding: 3px 3px ! important;
    // }
    .topic-button-plus{
      width: 85px;
      font-size: .9em;
      padding-right: 13px;
      padding-left: 13px;
    }
  }

  @media (max-width:360px){
    .topic-button-plus{
      width: 85px;
      font-size: .9em;
      padding-right: 13px;
      padding-left: 13px;
    }
  }
