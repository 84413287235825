body {
  margin: 0;
  background-color: rgb(247, 247 , 247);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./Fonts/Lato/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Glacial';
  src: local('Glacial'), url(./Fonts/Glacial/GlacialIndifference-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(./Fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./Fonts/Raleway/static/Raleway-Regular.ttf) format('truetype');
}

.hoverable:hover{
  cursor: pointer; 
}
@-ms-viewport{
  width: device-width;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');


div {
    width: 100%;
}

.body-style {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	  font-family: 'OpenSans';

    width: 100% ! important;

}

h1 {
	/* font-weight: bold; */
    margin: 0;
    font-size: 3.5em ! important;

}

h2 {
    text-align: center;

}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
  color: inherit !important; 


}
a:hover{
  text-decoration: none !important;
}

.button-main {
	border-radius: 20px;
	border: 1px solid #FCAB10;
	 /* background-color:#00C49A;  */
	background-color:#FCAB10;
	color: #FFFF;
	font-size: 12px;
	/* font-weight: bold; */
	padding: 12px 45px;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
	transition: transform 80ms ease-in;
}

.button-main:active {
	transform: scale(0.95);
}

.button-main:focus {
	outline: none;
}
textarea{
  background-color: #eee;
  border: none;
  width: 100%;
  overflow: stretch;

  resize: none;

}

.button-main.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.input-spacing {
	background-color: #eee;
	border: none;
	padding: 5px 15px;
	margin: 8px 0;
	width: 100%;
}
.content-wrapper{
  padding-bottom: 4.5rem;  
  background-color: #ebedf2;
}


@media (max-width:1008px){
  .input-spacing {
    padding: 5px 15px;
    margin: .4em 0;
    
  
  }

	

} 
@media (max-width:768px){
  .input-spacing {
    padding: 4px 15px;
    margin: .2em 0;
  }

  p{
    font-size: .8em;
  }
  

} 

@media (max-width:600px){

  .button-main {
    padding: 1em 1.9em;
    font-size: .7em;

  }
  .input-spacing {
    padding: 4px 15px;
    margin: .2em 0;
    font-size: .8em;
  }

  p{
    font-size: .8em;
    margin-bottom: .6em;
  }

  a {
    font-size: .8em;
    margin-top: .6em;
    margin-bottom: .6em;
  }

  form{
    padding: 0 1.3em;
  }


} 

@media (max-width:480px){


  .button-main {
    padding: .7em 1.9em;
    font-size: .5em;
    margin-top: .8em;

  }
  .input-spacing {
    padding: 5px 15px;
    margin: .2em 0;
    font-size: .5em;
  }

  p{
    padding-top: .2em;
    font-size: .5em;
    line-height: 1em;
    margin-bottom: .6em;
    margin-top: 1em;
  }

  a {
    font-size: .6em;
    margin-top: .6em;
    margin-bottom: .15em;
  }

  form{
    padding: 0 1.2em;
  }


} 

@media (max-width:320px){

  .button-main {
    padding: .7em 1.9em;
    font-size: .5em;
    margin-top: .8em;

  }
  .input-spacing {
    padding: 5px 15px;
    margin: .2em 0;
    font-size: .5em;
  }

  p{
    padding-top: .2em;
    font-size: .5em;
    line-height: 1em;
    margin-bottom: .6em;
    margin-top: 1em;
  }

  a {
    font-size: .6em;
    margin-top: .6em;
    margin-bottom: .15em;
  }

  form{
    padding: 0 1.2em;
  }




}

