.set{

    margin-top: 30px ! important;
 
    vertical-align: middle;
    padding: auto auto;
    box-shadow: .5em .5em .25em rgba(0,0,0,.2);
    background-color: white;
    text-align: center;
    font-size:16pt;
    border-radius: 10px;
    height: 170px;
  }
  .set{
    margin-top: 15px !important;
    min-width: 120px;
    // width: 140px;
    // height: 110px;
    padding-right: 5px;
    color: white;
 


    
    

  }
  .set-text{
    margin-top: 20px;
    border-bottom-style: solid;
    border-bottom-color: grey;
    border-bottom-width: 2px;
    text-align: left;
    padding-left: 50px;
    font-size: 18pt;
  }

  .set-list-center{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    align-content: flex-start;
    margin-bottom: 25px;
    padding-left: 30px;    
  }
  .set-list-set-link{
    height: fit-content;
    width: fit-content;

  }
  .set-text-wrapper{
    vertical-align: middle;
    margin: auto auto;
    text-align: center;
    padding-top: 1.5em;
    color: black;
}
.set-set-name{
    word-wrap: break-word;
    overflow: wrap;
    padding-bottom: 5px;
  }
  .set-list .set{
    overflow: hidden;
    margin-bottom: 2em;
    flex: 1 0 20%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    max-width: 22%;
}
.set-list .set .set-texts{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    text-align: left;
}
.set-list .set .set-name{
    max-width: 90%;
    color: #5899E2;
    font-size: 1.5em;
    word-wrap: break-word;
    line-height:35px;
    
}
.set-list .set .set-description{
    color: rgb(54, 97, 158);
    font-size: .9em;
    font-weight:500;
    line-height: 20px;
    
}


  .set-list-card-counter{
    font-size: 16pt;
    display: flex;
    justify-content: center;
  }
  .set-list-card-counter-wrapper{

    width: 100%;
    text-align: center;
    padding: auto auto;
    margin: auto auto;
    height: fit-content;
  }
  .topic-set-plus{
    width: 100% ;
    text-align: center;
  }
  .topic-set-plus .plus{
    margin-top: 0;
    margin-bottom: 20px;
  }

  @media (max-width:1200px){
    .set-list .set .set-name{
      font-size: 1.3em;
      line-height: 25px;
    }

  }
  @media (max-width:1000px){
 
    .set-list .set .set-name{
      font-size: 1.1em;
      line-height: 20px;
    }
    .set-list .set .set-description{
      font-size: .8em;
      word-wrap: break-word;
    }
    .set-list .set{
      height: 130px;
      margin-bottom: 1.5em;
    }




  }

  @media (max-width:768px){
    .set-list .set .set-name{
      font-size: .7em;
      line-height: 18px;
      font-weight: 600;
    }
    .set-list .set .set-description{
      font-size: .5em;
      line-height: 14px;
    }
    .set-list .set{
      height: 110px;
      margin-bottom: 1em;
      min-width: 100px;
    }
    

  }
  @media (max-width:600px){
    .set-list .set .set-name{
      font-size: .4em;
      line-height: 12px;
      font-weight: 600;
    }
    .set-list .set .set-description{
      font-size: .35em;
      line-height: 10px;
    }
    .set-list .set{
      height: 80px;
      margin-bottom: 1em;
      min-width: 80px;
    }
  }

  @media  (max-width:480px){
    .set-list .set .set-name{
      font-size: .35em;
      line-height: 8px;
      font-weight: 700;
      margin-left: 0;
    }
    .set-list .set .set-description{
      font-size: .3em;
      line-height: 8px;
    }
    .set-list .set{
      margin-left: 1.5%;
      margin-right: 1.5%;
      height: 50px;
      margin-bottom: 1em;
      min-width: 60px;
    }
  }

  @media (max-width:360px){
    .set-list .set .set-name{
      font-size: .35em;
      line-height: 8px;
      font-weight: 700;
      margin-left: 0;
    }
    .set-list .set .set-description{
      font-size: .3em;
      line-height: 8px;
    }
    .set-list .set{
      margin-left: 1.5%;
      margin-right: 1.5%;
      height: 60px;
      margin-bottom: .5em;
      min-width: 60px;
    }
  
  }





