.about-grid{
    display: grid;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 3fr 7fr;
    grid-template-rows: 70px 9fr;
    padding-top: 4em;
    height: 100%;
    margin-bottom: 7%;
    // background-color: green;
}

.about-grid-title{
    grid-column-start: 1;
    grid-row-start: 2;
    text-align: center;
    font-weight: bold;
    font-size: 2.5em;
    font-family: 'Lato';
    width: 100%;
    text-align: center;
    padding: auto auto ;
    vertical-align: middle;
}
.about-image{
    width: 70%;
    vertical-align: middle;
    margin: auto auto;
    padding: auto auto ;
   
    

}
.about-grid-text{
    // padding-left: .5em;
    // margin-top: 10px;
    font-size: 1em;
    font-family: 'OpenSans';
    color: rgba(57, 87, 128 , 1);
}



.about-grid-transparent{
    color: rgba(57, 87, 128 , 1);
    grid-column-start: 2;
    grid-row-start: 1;
    width: 80%;
    height: fit-content;
    font-size: 2.8em;
    font-weight: normal;
    margin-left: 4%;
    font-family: 'Raleway';
    font-weight: 1000;
    // padding-right: 1em;
    text-align: left;

  
}

.about-grid-body{
    grid-column-start: 2;
    text-align: left;
    padding-right: 7%;
    margin-left: 4%;
    font-size: 1.6em;
    height: 100%;
    width: 80%;

}
@media(max-width: 1200px)
{
    .about-grid-body{

        font-size: 16pt;
    
    }

    .about-grid-title{

        font-size: 18pt;
    }
    .about-grid{
        display: grid;
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        grid-template-columns: 3fr 7fr;
        grid-template-rows: 70px 9fr;
        padding-top: 4em;
        height: 100%;
        margin-bottom: 7%;
    }
}

@media(max-width: 1400px)
{
    .about-grid-body{

        font-size: 16pt;
    
    }

    .about-grid-title{

        font-size: 18pt;
    }
}
@media(max-width: 1000px)
{
    .about-grid-body{

        font-size: 13pt;
        padding-right: 2%;
    
    }

    .about-grid-transparent{
        font-size: 2.0em;
    }

    .about-grid{   
        grid-template-rows: 60px 9fr;
        padding-top: 2.5em;
  
    }

}
@media(max-width: 800px)
{
    .about-grid-body{

        font-size: 11pt;
        padding-right: 2%;
    
    }

    .about-grid-transparent{
        font-size: 1.6em;
    }

    .about-grid{   
        grid-template-rows: 45px 9fr;
        padding-top: 1.8em;
        margin-left: 3%;
        margin-right: 3%;
    }

}

@media(max-width: 600px)
{
    .about-grid-body{

        font-size: 8.5pt;
    
    }

    .about-grid-transparent{
        font-size: 1.0em;
    }

    .about-grid{   
        grid-template-rows: 30px 9fr;
        padding-top: 1.7em;
    }
}

@media(max-width: 480px)
{
    .about-grid-body{

        font-size: 7pt;
    
    }

    .about-grid-transparent{
        font-size: 1.0em;
    }

    .about-grid{   
        grid-template-rows: 30px 9fr;
        padding-top: 1.5em;
    }
}

@media(max-width: 360px)
{
    .about-grid-body{

        font-size: 6pt;
    
    }

    .about-grid-transparent{
        font-size: .8em;
    }

    .about-grid{   
        grid-template-rows: 30px 9fr;
        padding-top: 1.5em;
    }
}

@media(max-width: 320px)
{
    .about-grid-body{

        font-size: 5.5pt;
    
    }

    .about-grid-transparent{
        font-size: .7em;
    }

    .about-grid{   
        grid-template-rows: 25px 9fr;
        padding-top: 1.5em;
    }
}