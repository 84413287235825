.landing-wrapper{
    background-color: white;
}

.landing-text-1{
    display: inline-block;
    margin: auto auto;
    font-size: 4.5em;
    vertical-align: middle;
    // margin-top: 150px;
    opacity: 1 ! important;
    font-family: 'Raleway';

}
.mobile-landing-top-bar{
    height: 10vh;
    background-color: rgba(57, 87, 128 , 1);
}


.mobile-title-logo{
    background-image: url("../images/flippso-logo-blue-back.png");
    // background-position: center center;

    height: 6vh;
    width: 6vh;
    vertical-align: middle;
    background-size: cover;
    margin: auto auto;
    margin-left: 10vw;
    margin-right: 0em;

}
.mobile-title-text{
    font-size: 18pt;
    display: inline;
    color: white;
    margin: auto auto;
    margin-left: 1em;
    width:fit-content;
    
}
.mobile-ham-stack{
    font-size: 12pt;
    display: inline;
    color: white;
    margin: auto auto;
    margin-right: 10vw;
    width:fit-content;
    
}
.mobile-title-wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    // justify-content: right;
    padding-top: 2vh ;
    padding-bottom: 2vh;
}

.landing-section{
    padding: auto auto;
    text-align: center;
    height: calc(100vh - 100px);
    background-repeat: no-repeat;
    background-size: cover;

    vertical-align: middle;
    text-align: center;
    // padding-top: 120px;
}

.landing-section-b{
    
    padding: auto auto;
    text-align: center;
    height: calc(100vh - 100px);
    background-repeat: no-repeat;
    background-size: cover;

    vertical-align: middle;
    text-align: center;
    // padding-top: 120px;
}

.landing-info-section-b{
    padding: auto auto;
    text-align: center;
    height: calc(80vh );
    background-repeat: no-repeat;
    background-size: cover;
    border-top-color: #FCAB10;
    border-top-style: solid;
    border-top-width: 10px;
    border-bottom-color: #5899E2;
    border-bottom-style: solid;
    border-bottom-width: 10px;
    background-color: rgba(57, 87, 128 , 1);
    vertical-align: middle;
    text-align: center;
    display: grid;
    grid-template-columns: 5fr 3.8fr;
    column-gap: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
}
.landing-demo-section-b{

    text-align: center;
    height: fit-content;
    min-height: calc(90vh );
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    vertical-align: middle;
    padding-bottom: 5%;
    padding-top: 3%;
    text-align: center;
    display: grid;
    grid-template-columns: 1.5fr 4fr;
    column-gap: 5%;

    // padding-top: 120px;
}

.landing-button-1
{
    display: inline-block;
    font-size: 1.5em;
    background-color:  #5899E2;
    color: white;
    border-radius: 2px;
    box-shadow: 1em 1em 2em rgba(0,0,0,.35);
    text-align: center;
    vertical-align: middle;
    padding: 1em 1.5em;
    font-family: 'Raleway';
    width: 90%;

    opacity: 1 ! important;
    
    
}
.landing-transparent{
    background-color: rgba(57, 87, 128 , .9);
    width: fit-content;
    width: 500px;
    height: 45%;
    // padding-top: 50px;
    padding-bottom: 23%;
    padding-left: 1em;
    padding-right: 1em;
    position: absolute;
    bottom: 0;
    left: 10%;
    color: white;

}
.landing-button-text{
    font-size: 4em;
    font-weight: bold;
}
.landing-button-wrapper-b{
    position: absolute;
    right: calc((100vw - 950px) / 2);
    top: calc((100vh - 550px - 2em) / 2);
    left: calc((100vw - 950px) / 2);
    width: fit-content;
    text-align: center;
    margin: auto auto;
}

.landing-button-wrapper{
    position: absolute;
    right: 20%;
    top: 25%;
    width: fit-content;
    text-align: center;
    margin: auto auto;
}
.image-1{
    background-image: url("../images/Female-business-people-using-laptop-Stock-Photo.jpg");
    background-position: center center;

}
.image-1-b{
    background-image: url("../images/Female-business-people-using-laptop-Stock-Photo.jpg");
    background-position: center center;
    

}

.image-2{
    background-image: url("../images/girl_studying.jpg");
}
.landing-list-item{
    width: fit-content;
    font-size: 14pt;
    margin-top: .4em;
    text-align: left;
}
.landing-list{
    width: 100%;
    padding-left: 12%;
    margin-top: 1.5em;
}
.landing-header-text{
    margin-top: .3em;
    font-size: 36pt;
    color: white;
    border-bottom: #FCAB10;
    border-bottom-style: solid;
    border-bottom-width: 3px;

    width: fit-content;
    
    font-family: 'Raleway';

}
// <div className="landing-info-text">
// <div className="landing-info-text-header" style={{"color" : "white"}}>
//   How it works</div>
// <div className="landing-info-text-body" style={{"color" : "white"}}>


.landing-demo-text{
    
    padding-right: 10%;
    height: 100%;
    grid-column-start: 2;
    grid-row-start: 1;
}

.landing-info-text{
    
    text-align: left;
    
}
.start-studying-button{
    text-transform: none;
    font-size: 2em;
    border-width: 2px;
    background-color: #FCAB10;
    font-family:'Glacial';
    color: white;
    border-color: #FCAB10;
    border-radius: 20px;
    grid-column-start: 2;
    grid-row-start: 1;
    width: 80% ! important;
    padding-bottom: 6px;
    padding-top: 6px;
    margin: auto 0;
  
    display: block;
    margin-top: 1em;
    

    // margin: auto auto;
}
.landing-info-image{
    grid-column-start: 2;
    background-image: url("../images/howitworks-placeholder.PNG");
    background-position: center center;
    width: 100%;
    height: 65%;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: center;
    margin: auto auto;

}
.landing-demo-image{
    background-image: url("../images/studybar-placeholder.PNG");
    background-position: center center;
    width: 55%;
    height: 90%;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: top;
    margin: auto auto;
    margin-top: 0em;

}
.landing-info-text-body
{
    font-size: 1.5em;
    text-align: left;
    font-family: "openSans";
     
}

.landing-info-text-header{
    font-size: 2.4em;
    text-align: left;
    margin-bottom: 1em;
    font-family: "raleway";
    font-weight: bold;
}

.mobile-landing-top{
    background-color: rgb(57, 87, 128 );
    width: 100%;
    height: 70vh;
    z-index: 99;
    padding-left: 8%;
}
.mobile-landing-header{
    width: 100%;
    height: 10vh;
}
.mobile-landing-bold-text{
    font-size: 3.3em;
    font-weight: bold;
    color: white;
    width: fit-content;
    line-height: 1.2em;
}
.mobile-landing-text-wrapper{
    width: 100%;
    background-color: rgba(57, 87, 128 , 1);
    height: 55vh;
    padding-left: 6vw ;
    
}
.mobile-landing-image{
    background-image: url("../images/mobile-title-image.jpg");
    height: 35vh;
    background-position: center center;
    background-size: 100% 35vh;
}
.mobile-landing-button{
    display: inline-block;
    font-size: 1.5em;
    background-color:  #5899E2;
    color: white !important;
    border-radius: 2px;
    box-shadow: 1em 1em 2em rgba(0,0,0,.35);
    text-align: center;
    vertical-align: middle;
    padding: .7em 1em;
    font-family: 'Raleway';
    width: 260px;

    opacity: 1 ! important;
}
.mobile-landing-sub-text{
    width: 260px;
    word-wrap: break-word;
    color: white;
    padding-top: 1em;
  
    font-family: "OpenSans";
}
.landing-button-text-b{
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 1em;
}



@media (max-width:1350px){

    .landing-header-text{

        font-size: 1.5em;
    }
    .landing-list-item{
        font-size: .9em;
    }
    // .landing-button-wrapper{
    //     right: 15%;
    // }
    .landing-button-1{
        font-size: 1.3em;
    }
    .landing-button-text{
        font-size: 2.5em;
    }
    .landing-info-text-body{
        font-size: 1.4em;
    }
    .landing-button-text-b{
        font-size: 3.5em;
        font-weight: bold;
    }
    .landing-transparent{
        width: 400px;
    }



} 
@media (max-width:1208px){
    .landing-button-wrapper-b{

        right: calc((100vw - 650px) / 2);
        top: calc((100vh - 500px - 2em) / 2);
        left: calc((100vw - 650px) / 2);

    }
    .landing-info-text-header{
        padding-top: 10%;
        font-size: 2em;
    }
    .landing-info-text-body{
        font-size: 1.2em;
    }
    .landing-info-image{
        font-size: 1.2em;
    }
    .start-studying-button{
        font-size: 1.7em;
        width: 80% ! important;
        padding-bottom: 6px;
        padding-top: 6px;
        margin: auto 0;
        display: block;
        margin-top: 1em;
       
        
    
        // margin: auto auto;
    }
    
}

@media (max-width:950px){

    .landing-header-text{

        font-size: 1.3em;
    }
    .landing-list-item{
        font-size: .75em;
    }
    .landing-list{
        margin-top: .5em;
    }
    .landing-button-wrapper{
        right: 15%;
        top:23%;
    }
    .landing-button-1{
        font-size: 1.1em;
    }
    .landing-button-text{
        font-size: 2.0em;
    }
    .landing-transparent{
        width: 325px;
    }
    .landing-button-text-b{
        font-size: 3em;
        font-weight: bold;
    }
    

    


}
@media (max-width:800px){
    .landing-button-wrapper-b{

        right: calc((100vw - 650px) / 2);
        top: calc((100vh - 550px ) / 2);
        left: calc((100vw - 650px) / 2);

    }
    .landing-button-text-b{
        font-size: 2.5em;
        font-weight: bold;
    }
    .landing-info-text-header{
  
        font-size: 1.3em;
    }
    .landing-info-text-body{
        font-size: 1.0em;
    }
    .start-studying-button{
        font-size: 1.3em;
        width: 80% ! important;
        padding-bottom: 4px;
        padding-top: 4px;
        margin: auto 0;
        display: block;
        margin-top: 1em;
        
    
        // margin: auto auto;
    }
    
}

@media (max-width:550px){

    .landing-header-text{

        font-size: 1.3em;
    }
    .landing-list-item{
        font-size: .75em;
    }
    .landing-list{
        margin-top: .5em;
    }
    .landing-button-wrapper{
        text-align: center;
        margin: auto auto;
        right: 0;
        left: 0;
    }
    .landing-button-1{
        font-size: 1.1em;
    }
    .landing-button-text{
        font-size: 2.0em;
    }
    .landing-transparent{
        width: 325px;
        text-align: center;
        margin: auto auto;
        right: 0;
        left: 0;
    }


}

@media (max-width:600px){
    .landing-header-text{

        font-size: 1.5em;
    }


} 

@media screen and (max-width:480px){


    .landing-button-1
    {
        font-size: 1.5em;
        padding: .7em 1.2em;
    }

    .landing-button-text{
        font-size: 2.2em;
    }

    .footer-link{
        font-size: .5em;
    }

    .landing-header-text{

        font-size: 1.5em;
    }
    .landing-list-item{
        font-size: .95em;
    }



} 

@media (max-width:385px){
    .mobile-title-text{
        font-size: 16pt;
    }
    .landing-button-text{
        font-size: 2.2em;
    }
    .landing-button-1{
        font-size: 1.5em;
    }
    .footer-link{
        font-size: .5em;
    }

    .landing-header-text{

        font-size: 1.5em;
    }
    .landing-list-item{
        font-size: .95em;
    }
    .mobile-landing-sub-text{
        font-size: .9em;
        width: 225px;
    }
    .mobile-landing-button{
        width: 225px;
        font-size: 1.3em;
    }
    .mobile-landing-bold-text{
        font-size: 2.8em;
        line-height: 1.1em;
    }


}
@media (max-width:330px){
    .mobile-title-text{
        font-size: 14pt;
    }
    .mobile-landing-bold-text{
        font-size: 2.6em;
        line-height: 1.0em;
    }
    .landing-button-text{
        font-size: 2.2em;
    }
    .landing-button-1{
        font-size: 1.5em;
    }
    .footer-link{
        font-size: .5em;
    }

    .landing-header-text{

        font-size: 1.15em;
    }
    .landing-list-item{
        font-size: .8em;
    }
    .landing-transparent{
        width: 280px;
        text-align: center;
        margin: auto auto;
        right: 0;
        left: 0;
    }



}