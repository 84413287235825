


  .BrainhubCarousel__arrows{
    background-color:#FCAB10 ! important ;
    border-radius: 20px;
  }
  .button-wrapper{
    width: fit-content;
    height: auto;
    text-align: center;
    margin: auto auto;

    margin-top: 25px;

  }

  
  .topic-icon{
    font-size: 30pt;
  }




.ad-wrapper{
  position: relative;
  right: 0;
}


.set-list-main-grid{
  padding-top: 5em;
}

  .edit-icon{

    text-align: right;
    font-size: 12pt;
    position: absolute;
    right: 20px;
    width: fit-content;
    
    z-index:2;
  }
  .edit-icon-wrapper{
    min-height: 35px;
    margin-right: 20px;
    padding-top: 10px;
    vertical-align: top ! important;
    text-align: right;
    justify-content: right;
    width: 100%;
  }

  .edit-icon:hover{
    font-size: 14pt;
    outline: green;
    
  }

  .topic-text{
    vertical-align: middle;
    padding-top: 10px;
  }
  .topic{
      
      min-width: 200px;
      height: 150px;
      color: white;
      border-radius: 10px;
      margin-top: 50px;
      margin-bottom: 50px;
      font-size:16pt;
      
      
      box-shadow: .5em .5em .25em rgba(0,0,0,.2);
      background-color: white;
      text-align: center;
  }

  .selected{
    border-style: solid;
    border-width: 2px;
    border-color: #00CFC1;
  }
  .BrainhubCarouselItem{
    margin-left: 20px ! important;
  }
  .BrainhubCarouseTrack{
    min-height: 200px;
    
  }
  .set-list-topic-carosuel{
    width: 700px;
    min-height: 200px;
    text-align: center;
    margin: auto auto;
    vertical-align: middle;
    overflow: hidden;
      
  }
  .BrainhubCarousel__trackContainer{
    min-height: 200px;
  }
  .carosuel{
    height: 200px;
    
  }

  .welcome{
    text-align: center;

  }
  .main-welcome{
    font-size: 2em;
    width: fit-content;
    margin: auto auto;
    margin-bottom: .7em;
    padding-right:12em;
  }
  .main-welcome-subtext{
    font-size: 1.5em;
    // padding-left: 3%;
  }
  


  .topic-input-wrapper{
    // height: 50px ! important;
    width: 50vw ! important;
    margin: auto auto;
    
   
  }

  .topic-select-wrapper{
    // height: 50px ! important;
    margin-top: 20px;
   
  }

  .set-list-selection{
    width: 100%;
    min-height: 800px;

 
  }

  .set-list-button{
    text-align: center;
    margin:auto auto;
    justify-content: center;
  }




  @media (max-width:1000px){


    .edit-icon{
      padding-top: 5px;
      font-size: 10pt;
      min-height: 25px;
    }

    .set-list-topic-carosuel{
      width: 500px;
      min-height: 200px;        
    }


    .topic-text{
      font-size: .7em;
    }
    .edit-icon{
      padding-top: 12px;
      font-size: 10pt;
      min-height: 25px;
    }
    .edit-icon:hover{
      font-size: 12pt;
    }
  
    .topic{
      width: 150px;
      min-width: 150px;
      height: 125px;
    }

} 


@media (max-width:768px){
  .main-welcome{
    font-size: 2em;
    width: fit-content;
    margin: auto auto;
    margin-bottom: .7em;
    padding-right:12em;
  }

  .main-welcome-subtext{
    font-size: 1.5em;
  }

  .edit-icon{
    padding-top: 12px;
    font-size: 10pt;
    min-height: 25px;
  }


  .set-list-topic-carosuel{
    width: 500px;
    // min-width: 900px;
    min-height: 200px;
      
  }

  .topic-text{
    font-size: .7em;
  }

  .topic{
    
    width: 150px;
    // width: 100px;
    min-width: 150px;
    height: 125px;
  }




} 

@media (max-width:600px){

  .main-welcome{
    font-size: 1.5em;
    width: fit-content;
    margin: auto auto;
    margin-bottom: .7em;
    padding-right:13em;
  }
  .main-welcome-subtext{
    font-size: 1.2em;
    // padding-left: 3%;
  }

  .set-list-button{
    width: 90px;
    font-size: 1em;
    padding-right: 15px;
  }

  .edit-icon{
    padding-top: 12px;
    font-size: 10pt;
    min-height: 25px;
  }
  .edit-icon:hover{
    font-size: 12pt;
  }

  .set-list-topic-carosuel{
    width: 420px;
    // min-width: 900px;
    min-height: 200px;
    height: 200px;

      
  }
  .carosuel{
    height: 150px;
    
  }

  .topic-text{
    font-size: .7em;
    padding-top: 5px;
  }
  .topic-icon
  {
    font-size: 20pt;
  }
  .topic{
    
    width: 125px;
    // width: 100px;
    min-width: 125px;
    height: 100px;
  }

 

} 

@media  (max-width:480px){

  .BrainhubCarousel__arrows{
    padding: 15px 15px ! important;
  }


  .main-welcome{
    font-size: 1.2em;
    width: fit-content;
    margin: auto auto;
    margin-bottom: .7em;
    padding-right:13em;
  }
  .main-welcome-subtext{
    font-size: .95em;
    // padding-left: 3%;
  }

  .set-list-button{
    width: 90px;
    font-size: 1em;
    padding-right: 15px;
  }

  .edit-icon{
    padding-top: 8px;
    font-size: 8pt;
    min-height: 20px;
  }
  .edit-icon:hover{
    font-size: 10pt;
  }


  .set-list-topic-carosuel{
    width: 320px;
    // min-width: 900px;
    min-height: 125px;

      
  }
  .topic-icon
  {
    margin-top: 5px;
    font-size: 15pt;
  }
  .carosuel{
    height: 125px;
    
  }

  .topic-text{
    font-size: .55em;
  }

  .topic{
    width: 110px;
    min-width: 100px;
    height: 85px;
  }

} 

@media (max-width:360px){

  .BrainhubCarousel__arrows{
    padding: 15px 15px ! important;
  }
  span{
    padding: 3px 3px ! important;
  }


  .main-welcome{
    font-size: 1.0em;
    width: fit-content;
    margin: auto auto;
    margin-bottom: .7em;
    padding-right:13em;
  }
  .main-welcome-subtext{
    font-size: .8em;
    // padding-left: 3%;
  }

  .set-list-button{
    width: 90px;
    font-size: 1em;
    padding-right: 15px;
  }

  .edit-icon{
    padding-top: 8px;
    font-size: 8pt;
    min-height: 20px;
  }
  .edit-icon:hover{
    font-size: 10pt;
  }
  .set-list-topic-carosuel{
    width: 280px;
    // min-width: 900px;
    min-height: 125px; 
  }
  .topic-icon
  {
    margin-top: 5px;
    font-size: 15pt;
  }
  .carosuel{
    height: 125px;
    
  }

  .topic-text{
    font-size: .55em;
  }

  .topic{
    
    width: 100px;
    // width: 100px;
    min-width: 100px;
    height: 80px;
  }




}

