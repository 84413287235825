.reset-form-container{
    text-align: center;
    
    margin: 8em auto;

    width: fit-content;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
 
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
    overflow: hidden;
    padding: 4em 5em;
	// width: 950px;
	// max-width: 100%;
	// max-height: 500px;
	// min-height: 150px;
}

.reset-body{
    text-align: center;
    width: 100%;
}

.reset-subtext{
    font-size: 1.3em;
    margin-top: .5em;
    margin-bottom: .5em;
}

.reset-input{
    width: 50%;
    margin-bottom: .5em;

    min-width: 300px;
    max-width: 500px;
    display: block;
    margin: 1.5em auto;

    
}

.reset-form-button{
 
    display: block;
    margin: .5em auto;
    // text-transform: none! important;
    
}

@media(max-width: 1000px)
{
    .reset-input{

        font-size: 1.1em;
        margin-bottom: .8em;

       
    }
    .reset-subtext{
        font-size: 1.1em;
    }
}

@media(max-width: 760px)
{
    .reset-input{

        font-size: 1em;

       
    }
    .reset-subtext{
        font-size: .9em;
    }
}
@media(max-width: 600px)
{
    .reset-input{

        font-size: .9em;

       
    }
    .reset-subtext{
        font-size: .9em;
    }
}

@media(max-width: 480px)
{
    .reset-input{
        min-width: 250px;
        font-size: .7em;

       
    }
    .reset-subtext{
        font-size: .7em;
    }
}

@media(max-width: 360px)
{
    .reset-input{
        min-width: 225px;
        font-size: .7em;

       
    }
    .reset-subtext{
        font-size: .7em;
    }
}
