

.login-subtext{
	font-size: .8em;
}


.login-body {
	display: flex;
	height: 85vh;
	vertical-align: middle;
	padding: auto auto;
	text-align: center;
	align-items: center;
	justify-content: center;
	
}
.google-logo{

	background-image: url("../images/google-logo.png");

	/* display: inline; */
	width: 40px;
	height: 40px;
	background-size:40px 40px;
	margin-top: auto;
	margin-bottom: auto;
	vertical-align: middle;

	/* background-size: 10% 10%; */
	
}
.google-logo-wrapper{
	height: 50px;
	margin-top: -10px;
	padding-top: 5px;
	background-color: white;
	width: fit-content;
}
.google-button{
	width: 220px;
	height: 60px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 6px;
	padding-right: 0;
	margin-top: 1.5em;
	display: flex;
	flex-direction: row;
	background-color: #5899E2;
	border-style: none;
	border-radius: 4px;
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
	0 10px 10px rgba(0,0,0,0.22);

	vertical-align: middle;
}
.google-button-text{
	padding-left: 1em;
	font-weight: normal;
	color: white;
	font-size: 10pt;
	margin-top: auto;
	width: fit-content;
	text-transform: none;
	margin-bottom: auto;
	vertical-align: middle;
	/* text-align:right; */
	
}

.reset-body {
	/* display: grid; */
	/* grid-template-rows: .25fr; */
    height: 600px;
}
.invalid{
	border-width: 1px;
	border-color: red;
	border-style: solid;
}
.error{
	color: red;
	font-size: 10pt;
}
.login-main-container {
	

	
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
    overflow: hidden;
	margin: auto auto;
	justify-content: center;
	align-items: center;
	width: 950px;
	height: 650px;
	max-width: 100%;
	max-height: 500px;
	min-height: 150px;
	
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}
.accept-label{
	margin-left: 10px;
	vertical-align: middle;
	margin-bottom: auto;
	color: #5899E2;
	font-weight: 500;
	text-decoration: underline;
}
.accept-checkbox{
	margin-left: 10px;
	vertical-align: middle;

}
.checkbox-div{
	vertical-align: middle;
	text-align: left;

	
}
.sign-up-button{
	margin-top: 10px;
	text-transform: none !important;
	font-size: 1.2em !important;
}
.resetform-container {

	top: 0;
	height: 80%;
	width: 50%;

}
.create-input{
	font-size: 1.1em;
	margin-top: .5em;
}
.sign-up-form{
	height: 100%;
	vertical-align: top;
}
.form-header {
	color: #5899E2;
	font-size: 3.5em;
	font-weight: bold;
	line-height: 1.0em;
	/* font-family: 'Raleway'; */
	font-family: 'Glacial';
	/* font-family: 'Lato'; */
	/* font-family: 'OpenSans'; */
}

.sign-in-container {
	left: 0;
	width: 50%;
    z-index: 2;
 
}
.landing-button{
	background-color: #FCAB10;
	border-color: #FCAB10;
}
.login-main-container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.login-main-container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.Wrapper-Div {
    width: 100%;
}
.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.login-main-container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background:  #5899E2;
	background: -webkit-linear-gradient(to left,  #2990ff, #4173ac);
	background: linear-gradient(to left, #2990ff, #4173ac);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.login-main-container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.login-main-container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.login-main-container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.off-panel{
	color: white ! important;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.sign-in{
	margin-bottom: .3em;
}

@media (max-width:1208px){
	.login-main-container {

		width: 650px;
		max-width: 100%;
		height: 400px;
		min-height: 150px;
	}
	.input-spacing{

	}
	.create-input{
		font-size: .9em;
	}

	.form-header {
		color: #5899E2;
		font-size: 2.5em;
		font-weight: bold;
		line-height: 1em;
	}

	.login-subtext{
		font-size: .6em;
	}
	.google-logo{
		width: 30px;
		height: 30px;
		background-size:30px 30px;
		
	}
	.google-logo-wrapper{
		height: 40px;
		margin-top: -5px;
		padding-top: 5px;
	}
	.google-button{
		width: 160px;
		height: 50px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 6px;
		padding-right: 0;
		margin-top: 1.5em;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
		0 10px 10px rgba(0,0,0,0.22);
	
		vertical-align: middle;
	}
	.google-button-text{
		padding-left: 0px;
		font-size: 10pt;
		
	}
	

} 
@media (max-width:768px){
	.login-main-container {

		width: 530px;
		max-width: 100%;
		height: 300px;
		min-height: 150px;
	}
	.input-spacing{

	}
	.create-input{
		font-size: .7em;
	}
	.form-header {
		color: #5899E2;
		font-size: 2.0em;
		font-weight: bold;
		line-height: 1em;
	}

	.login-subtext{
		font-size: .5em;
	}
	.google-logo{
		width: 30px;
		height: 30px;
		background-size:30px 30px;
		
	}
	.google-logo-wrapper{
		height: 35px;
		margin-top: -7px;
		padding-top: 3px;
	}
	.google-button{
		width: 150px;
		height: 41px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 4px;
		padding-right: 0;
		margin-top: 1.0em;

	}
	.google-button-text{
		padding-left: 5px;
		padding-right: 5px;
		font-size: 8pt;
		
	}
	.sign-up-button{
		font-size: .8em ! important;
		padding: 6px 20px ! important;
	}



	

} 

@media (max-width:600px){
	.login-main-container {

		width: 400px;
		max-width: 100%;
		height: 250px;
		min-height: 150px;
	}
	.create-input{
		font-size: .6em !important;
		padding: 3px 6px !important;
	}
	.sign-up-button{
		font-size: .7em ! important;
		padding: 5px 15px ! important;
	}
	.google-button-text{
		font-size: .7em ! important;
	}
	.accept-label{
		font-size: .8em ! important;
	}
	.form-header {
		color: #5899E2;
		font-size: 1.6em;
		font-weight: bold;
		
	}

	.login-subtext{
		font-size: .5em;
	}



} 

@media (max-width:485px){

	.form-header {
		color: #5899E2;
		font-size: 1.8em;
		font-weight: bold;
	}

	.login-subtext{
		font-size: .5em;
	}
	.google-button-text{
		font-size: .6em ! important;
	}
	.overlay-panel{
		padding-left: .8em;
		padding-right: .8em;
	}
	.login-main-container {

		width: 380px;
		max-width: 100%;
		height: 300px;
		min-height: 150px;
	}
	.login-description{
		font-size: .8em;
		/* margin-top: 2.5em; */
	}
	.ghost{
		margin-top: 1em;
		font-size: .9em;
	}
	.landing-button{
		margin-top: 1em;
		font-size: .9em;
	}
	.login-input{
		font-size: .8em;
		margin-top: .5em;
	}

} 

@media (max-width:380px){
	.form-header {
		color: #5899E2;
		font-size: 1.8em;
		font-weight: bold;
	}

	.login-subtext{
		font-size: .5em;
	}
	.overlay-panel{
		padding-left: .8em;
		padding-right: .8em;
	}
	.login-main-container {

		width: 340px;
		max-width: 100%;
		height: 300px;
		min-height: 150px;
	}
	.login-description{
		font-size: .8em;
		/* margin-top: 2.5em; */
	}
	.ghost{
		margin-top: 1em;
		font-size: .9em;
	}
	.landing-button{
		margin-top: 1em;
		font-size: .9em;
	}
	.login-input{
		font-size: .8em;
		margin-top: .5em;
	}
	


}

@media (max-width:330px){

	.form-header {
		color: #5899E2;
		font-size: 1.8em;
		font-weight: bold;
	}

	.login-subtext{
		font-size: .45em;
	}
	.overlay-panel{
		padding-left: .8em;
		padding-right: .8em;
	}
	.login-main-container {

		width: 300px;
		max-width: 100%;
		height: 280px;
		min-height: 150px;
	}
	.login-description{
		font-size: .75em;
		/* margin-top: 2.5em; */
	}
	.ghost{
		margin-top: 1em;
		font-size: .9em;
	}
	.landing-button{
		margin-top: 1em;
		font-size: .8em;
	}
	.login-input{
		font-size: .8em;
		margin-top: .5em;
	}


	.google-button{
		width: 125px;

	}

}



