.input-field-feedback{
    width: 100%;
    height: fit-content;
    border-bottom-color: #FCAB10 ! important;
    border-bottom-width: 4px ! important;
    color: white ! important;
    font-family: "OpenSans";
    font-size: 1.5em;
    padding-bottom:2px ;
    padding-left:4px ;


}
.input-field-feedback::placeholder{
    color: white ! important;
}

.feedback-submit{
    text-transform: none;
    font-size: 1.5em;
    border-width: 2px;
    background-color: #5899E2;
    font-family:'Raleway';
    color: white;
    border-color: #5899E2;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;

    width: fit-content;
    justify-content: flex-end;
    margin: auto auto;

    margin-top: 1em;
    margin-bottom: 10px;

}
.feedback-button-wrapper{
    text-align: right;
    height: fit-content;

}
.feedback-bottom-spacer{
    margin-bottom: 75px ! important;
}
.feedback-header{
    font-size: 1.5em;
    margin-bottom: 1.5em;

    text-align: left;
   
}
.feedback-check-wrapper{
 text-align: right;
 vertical-align: middle;
 line-height: 50px;

}
.check-icon{
    //height: 150%;
    //width: 150%;
    font-size: 38pt;
    padding-top: 10px;
    color: #28b485;
   
}
.check-text{
    font-size: 22pt;
    vertical-align: middle;
    margin: auto auto;
    padding-bottom: 30px;
    height: 100%;

    color: #28b485;
    display: inline-block;
    
}
.feedback-title{
    margin-top: 1em;
    font-size: 2.8em;
    margin-bottom: .75em;

    text-align: left;
    font-family: "Raleway";
    font-weight: bold;
   
}

.feedback-wrapper{
    text-align: center;
    margin: auto auto;
    background-color: rgba(57, 87, 128 , 1);
    color: white;
    overflow:auto;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 5%;
}

@media(max-width: 1200px)
{
    .feedback-title{
        margin-top: 1em;
        font-size: 2.4em;
        margin-bottom: .75em;
    }
    .check-text{
        font-size: 18pt;
        padding-bottom: 25px;
    }
    .input-field-feedback{
        font-size: 1.4em;
    
    }   
    .feedback-submit{
        font-size: 1.3em;    
    }
    .feedback-bottom-spacer{
        margin-bottom: 65px ! important;
    }
    .feedback-header{
        font-size: 1.4em;
        margin-bottom: 1.5em;       
    }
    .feedback-check-wrapper{
     line-height: 40px;
    }
    .check-icon{
        font-size: 33pt;
        padding-top: 10px;       
    }
}

@media(max-width: 1000px)
{
    .feedback-header{
        font-size: 1.2em ;
    }
    .feedback-title{
        margin-top: 1em;
        font-size: 1.8em;
        margin-bottom: .75em;
    }
    .check-text{
        font-size: 18pt;
        padding-bottom: 25px;
    }
    .input-field-feedback{
        font-size: 1.2em;
    
    }   
    .feedback-submit{
        font-size: 1.3em;    
    }
    .feedback-bottom-spacer{
        margin-bottom: 65px ! important;
    }
    .feedback-check-wrapper{
     line-height: 40px;
    }
    .check-icon{
        font-size: 33pt;
        padding-top: 10px;       
    }
}
@media(max-width: 760px)
{
    .feedback-header{
        font-size: .95em ;
    }
    .feedback-title{
        margin-top: 1em;
        font-size: 1.6em;
        margin-bottom: .6em;
    }
    .check-text{
        font-size: 14pt;
        padding-bottom: 25px;
    }
    .input-field-feedback{
        font-size: .95em ;
        border-bottom-width: 3px !important;
    
    }   
    .feedback-submit{
        font-size: .9em; 
        font-weight: 700;
        padding-top: 5px;  
        padding-bottom: 5px; 
        margin-bottom: 0px;
    }
    .feedback-bottom-spacer{
        margin-bottom: 55px ! important;
    }
    .feedback-check-wrapper{
     line-height: 30px;
    }
    .check-icon{
        font-size: 20pt;
        padding-top: 0px;
        padding-bottom: 5px;       
    }
}
@media(max-width: 600px)
{
    .feedback-header{
        font-size: .7em ;
        margin-bottom: 5px;
    }
    .feedback-title{
        margin-top: 1em;
        font-size: 1.2em;
        margin-bottom: .6em;
    }
    .check-text{
        font-size: 10pt;
        padding-bottom: 30px;
    }
    .input-field-feedback{
        font-size: .7em ;
        border-bottom-width: 2px !important;
    
    }   
    .feedback-submit{
        font-size: .7em; 
        font-weight: 500;
        padding-top: 3px;  
        padding-bottom: 3px; 
        margin-bottom: 0px;
    }
    .feedback-bottom-spacer{
        margin-bottom: 65px ! important;
    }
    .feedback-check-wrapper{
     line-height: 35px;
    }
    .check-icon{
        height: 100%;
        font-size: 11pt;
        padding-top: 0px;
        padding-bottom: 11px;  
        margin-right: 5px;      
    }
}

@media(max-width: 480px)
{
    .feedback-header{
        font-size: .55em ;
        margin-bottom: 5px;
    }
    .feedback-title{
        margin-top: 1em;
        font-size: .9em;
        margin-bottom: .6em;
    }
    .check-text{
        font-size: 8pt;
        padding-bottom: 8px !important;
    }
    .input-field-feedback{
        font-size: .55em ;
        border-bottom-width: 2px !important;
    
    }   
    .feedback-submit{
        font-size: .55em; 
        font-weight: 500;
        padding-top: 3px;  
        padding-bottom: 3px; 
        margin-bottom: 0px;
    }
    .feedback-bottom-spacer{
        margin-bottom: 65px ! important;
    }
    .feedback-check-wrapper{
     line-height: 30px;
    }
    .check-icon{
        height: 100%;
        font-size: 10pt;
        padding-top: 0px;
        padding-bottom: 0px;  
        margin-right: 5px;      
    }
}

@media(max-width: 360px)
{
    .feedback-header{
        font-size: .45em ;
        margin-bottom: 5px;
    }
    .feedback-title{
        margin-top: 1em;
        font-size: .7em;
        margin-bottom: .6em;
    }
    .check-text{
        font-size: 8pt;
        padding-bottom: 8px !important;
    }
    .input-field-feedback{
        font-size: .45em ;
        border-bottom-width: 2px !important;
    
    }   
    .feedback-submit{
        font-size: .5em; 
        font-weight: 500;
        padding-top: 3px;  
        padding-bottom: 3px; 
        margin-bottom: 0px;
    }
    .feedback-bottom-spacer{
        margin-bottom: 65px ! important;
    }
    .feedback-check-wrapper{
     line-height: 30px;
    }
    .check-icon{
        height: 100%;
        font-size: 10pt;
        padding-top: 0px;
        padding-bottom: 0px;  
        margin-right: 5px;      
    }
}

