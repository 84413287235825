.type-study-input{
    grid-row-start: 2;
    grid-column-start: 2;
    width:95%;
    box-shadow: 1em 1em 2em rgba(0,0,0,.2);
    border-radius: 10px;
    height: fit-content;
    min-height: 150px;
    height: fit-content;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: auto auto;
    padding-bottom: 1em;
    padding-top: .5em;

}
.info-text{
    font-size: 20pt;
}
.info-text-wrapper{
    width: 100%;
    text-align: center;
    margin-top: 1em;
}
.unedited{
    width: fit-content;
    margin-left: 0px;
    padding-left: 0px;
}
.disable-rotate{
    transform: none ! important;
}
.editable-section{
    width: 95%;
    min-width: 1px;
    margin-right: 0px;
    padding-right: 0px;
}

.check-card-body{
    border-radius: 10px;
    max-width: 500px;
      
    background-color: white;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    min-height: 100px;
    margin: auto auto;
    
    padding: auto auto;

}

.study-content-editable{
    display:inline;
    word-wrap: break-word ! important;
    // overflow-wrap: break-word;
    height: fit-content;
    width: 100%;
}
.editable-wrapper{
    width: 90%;
    justify-content: center;
    border-bottom-width: 2px;
    border-bottom-color: black;
    border-bottom-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
}


.copy-font{
    font-size: 16pt;
}
.correct{
    
    color: green;
}
.incorrect{

    color: red;
}
.untyped{
    background-color:rgba(122,122,122,.2);
    
}
.type-study-input-field
{
    width: 70%;
    vertical-align: middle;
    font-size: 14pt;
    height: fit-content;
    word-wrap: break-word ! important;
    margin: auto auto;
    
    
}

.type-study-input-wrapper{
    grid-row-start: 3 ;
    padding-top: 20px;
}

.type-button{
    flex:auto;
    color: white;
    background-color:  #FCAB10;
    width: 20%;
    text-align: center;
    font-family: "Glacial";
    font-size: 20pt;
    padding-top: .3em;
    padding-bottom: .3em;
    border-radius: 5px;
    margin: 0 auto;
    height: fit-content;
    margin-top: .5em;

}
.type-option-button{
    color: white;
    background-color:  #FCAB10;
    width: fit-content;
    min-width: 25%;
    text-align: center;
    font-family: "Glacial";
    font-size: 20pt;
    padding-top: .2em;
    padding-bottom: .2em;
    padding-right: .3em;
    padding-left: .3em;
    border-radius: 5px;
    // margin: 1em auto;
    margin-left: 1em;


}

.type-options{
    grid-row-start:3;
    grid-row-end:3;
    display: flex;
    justify-content: space-around;
    width:100%;
    text-align: center;
    margin: 0 auto;
    font-size: 16pt;



}

@media (max-width: 1000px){
    .type-button{

        min-width: 90px;
        font-size: 16pt;
        padding-top: .3em;
        padding-bottom: .3em;
        border-radius: 5px;
  

    
    }
    .type-option-button{

        font-size: 16pt;
        padding-top: .4em;
        padding-bottom: .4em;
        padding-right: .3em;
        padding-left: .3em;
    
        // margin: 1em auto;
        margin-left: 1em;
    
    
    }
    .type-study-input-field{
        font-size: 12pt;
    }
}


@media (max-width: 1400px){

    .flip-button{

        font-size: 15pt;
        padding-right: .15em;
        padding-left: .15em;
        min-width: 100px;
        max-width: 200px;
        margin-left: 1em;
    
    }
    .copy-font{
        font-size: 15pt;
    }
    .flip-reset-button{
        font-size: 15pt;
    }
    .flip-switch-button{
        font-size: 20pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .5em;
    
    }
    .flip-reset-wrapper{

   
        
        
    }
}

@media (max-width: 1200px){

    .type-option-button{

        font-size: 15pt;
        padding-right: .15em;
        padding-left: .15em;
        min-width: 100px;
        max-width: 130px;
        margin-left: 1em;
    
    }
    .copy-font{
        font-size: 14pt;
    }
    .flip-reset-button{
        font-size: 15pt;
    }
    .flip-switch-button{
        font-size: 20pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .5em;
    
    }
    .flip-reset-wrapper{

   
        
        
    }
}

@media (max-width: 1000px){

    .flip-reset-button{
        font-size: 13pt;
    }
    .flip-switch-button{
        font-size: 17pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .3em;
    
    }
    .copy-font{
        font-size: 13pt;
    }
    .flip-reset-wrapper{

   
        
        
    }
    .type-option-button{

        font-size: 15pt;
        padding-right: .15em;
        padding-left: .15em;
        min-width: 100px;
        max-width: 100px;
        margin-left: 1em;
    
    }
}
@media (max-width: 800px){
    .type-button{

        min-width: 80px;
        font-size: 10pt;
        padding-top: .3em;
        padding-bottom: .3em;
        border-radius: 5px;
  

    
    }
    .copy-font{
        font-size: 12pt;
    }
    .type-study-input-field{
        font-size: 10pt;
    }
    .type-option-button{

        font-size: 10pt;
        padding-right: .1em;
        padding-left: .1em;
        min-width: 80px;
        max-width: 80px;
        margin-left: 1em;
    
    }
    .flip-reset-button{
        font-size: 13pt;
    }
    .flip-switch-button{
        font-size: 17pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .1em;
    }
    .flip-reset-wrapper{

        margin-left: 1em;
        margin-right: 1em;
        
        
    }
    
}
@media (max-width: 680px){
    .copy-font{
        font-size: 11pt;
    }
    .type-button{

        min-width: 60px;
        font-size: 10pt;
        padding-top: .3em;
        padding-bottom: .3em;
        border-radius: 5px;
  

    
    }
    .type-study-input-field{
        font-size: 8pt;
    }
    .type-option-button{

        font-size: 10pt;
        padding-right: .7em;
        padding-left: .7em;
        min-width: 70px;
        max-width: 70px;
        margin-left: .5em;
    
    }
}
@media (max-width: 550px){
    .type-study-input-field{
        font-size: 7pt;
    }
    .copy-font{
        font-size: 9pt;
    }
    .type-option-button{

        font-size: 9pt;
        padding-right: .7em;
        padding-left: .7em;
        min-width: 60px;
        max-width: 60px;
        margin-left: .5em;
    
    }
}

@media (max-width: 470px){
    .type-study-input-field{
        font-size: 7pt;
    }
    .copy-font{
        font-size: 7pt;
    }
    .type-option-button{

        font-size: 9pt;
        padding-right: .7em;
        padding-left: .7em;
        min-width: 60px;
        max-width: 60px;
        margin-left: .5em;
    
    }
}
