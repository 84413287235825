.input-body{
    width: 100%;
}


.setname-wrapper{
    width: 100%;
    display: flex;
    justify-content: left;
    height: fit-content;
    // margin-bottom: 1em;
    margin: auto auto;
    vertical-align: middle;
    padding-left: 0%;
    grid-column-start: 1;
    grid-row-start: 3;
    grid-column-end: 3;
    // padding-top: 2%;
}

.red-underline{
    border-bottom-color: red ! important;
}
.loading-wrapper{
    text-align: center;
    width: 100%;
    margin: auto auto;
    margin-top: 5em;
    font-size: 1.4em;
}
.date-text{
    width: auto;
    vertical-align: middle;
    padding: auto auto;
    margin: auto ;
    font-size: 1.3em;
    margin-right: 0em;
    height: 100%;
    color: rgb(57 , 87 , 128);
    display: inline-block;

}
.upload{
    font-size: 24pt;
    text-align: center;
    vertical-align: middle;
    padding-top: auto;
}
.create-date-wrapper{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    text-align: left;
}

.date-input{
    display: inline-block;
    height: fit-content;
    max-width: 200px; 
    margin-left: .5em;
    height: fit;
    background-color: white;
    color: black;
    border-bottom:#FCAB10 ;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    font-size: 1.3em;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    opacity: 1;
    
    width: fit-content;
    height: 50px;

    // padding: 0 0;

}
.menu-add-topic-plus svg{

    border-color: #5899E2 ;
}
.menu-add-topic-plus{
    border-style: none;
    font-size: 20pt;
    
    padding: 0 0 ;
    text-align: right;
    line-height: 20px;
    color:#FCAB10 !important;
    background-color: #5899E2 ;
    display: inline;
    height: fit-content;
    margin-left: 10%;

  
}
.menu-topic-text{
    font-size: 1.2em ! important;
    color: white ! important;
}
.select-modifier:hover{
    outline: none ! important;
    background-color: #5899E2 ! important;
    text-decoration: underline ! important;
    text-decoration-color: #FCAB10 ! important;
    text-decoration-thickness: 2px !important;

}
.menu-add-topic-input{
    border-style: none;
    border-bottom-style: solid;
    border-bottom-color: #FCAB10 ;
    background-color: #5899E2 ;
    color: white;
    width: 50%;
    margin-left: 8%;
    font-size: 1.2em;

}

.btn-success::focus{
    box-shadow: 0 0 0 0.2rem #5899E2 ;
}
.menu-add-topic-input::placeholder{
    color: white;
}
// .date-input::-webkit-calendar-picker-indicator {
//     filter: invert(1);

// }
.setname-input::placeholder{
    color: rgb(196, 195, 195);
    font-style: italic;
    font-family: "OpenSans";
}
.create-wrapper{
    background-color: rgb(235 , 237 , 242);
}
.setname-input{
    // margin-left: 5em;
    border: none;
    margin: auto 0;
    border-bottom: solid;
    border-bottom-width: 3px;
    border-bottom-color: white;
    background-color: transparent;
    height: fit;
    font-family: "OpenSans";
    font-size: 2.5em;
    width: 75%;
    min-width: 300px;
    word-wrap: break;
    color: black;
    border-bottom:#FCAB10 ;
    border-bottom-style: solid;
 
    // margin-left: 2.5em;
    // vertical-align: baseline;
    // justify-content: baseline;

}
.select-topic-wrapper{
    grid-row-start: 2;
    grid-column-start: 1;
    text-align: left;
}
.select-topic-description{
    font-size: 1.5em;
    color:  rgb(57 , 87 , 128);
    font-family: "OpenSans";
}
#set-topic-input::after{

    margin-left: 10%;
    

}
.dropdown{
    grid-column-start: 1;
    grid-column-end: 1 ;
}
#dropdown-arrow{
    background-color: #5899E2 ;
    border-style: none;
    font-size: 2.5em;
}
#set-topic-input{
    overflow: hidden;
    margin: auto 0;
    margin-right: 0em;
    // margin-left: 10em;
    width: 90%;
    height: 60%;
    border-radius: 10px;
    border-bottom: solid;
    min-width: 150px;
    text-align: center;
    vertical-align: middle;
    background: transparent;
    background-color: #5899E2 ;


    border: 1px solid #dfdfdf;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    padding: 12px 15px;
    color: white;
    font-size: 2.5em;

    


  
}
#dropdown-basic{
    background-color: blue;
}
.generate-button-top-wrapper{
    grid-row-start: 4;
    grid-column-start: 3;
    vertical-align: bottom;
    margin-top: auto;
    text-align: right;
}
.generate-button-top
{
    font-family: "Raleway";
    text-transform: none;
    font-size: 1.8em;
    border-width: 2px;
    background-color: rgb(180,202,234);
    font-family:'Raleway';
    color: rgba(57, 87, 128 , 1);
    border-color: rgb(180,202,234);
    border-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;

    width: fit-content;
    justify-content: flex-end;
    
    margin: auto auto;

    
}
.mid-section-title{
    text-align: center;
    font-size: 1.3em;
    margin-top: 1em;
}
.create-set-top{
   // background-color: #5899E2;
   background-color: white;
    border-color: #FCAB10;
    // border-width: 3px;
    // border-style: solid;
    text-align: center;
    width: 75% ;
    margin: auto auto;
    border-radius: 10px;
    box-shadow: 1em 1em 2em rgba(0,0,0,.2);
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: 2em;
    padding-right: .5em;
    margin-bottom: 4em;
    min-height: 400px;
    
    margin-top: 5%;
    display: grid;
    grid-template-columns: 33% 33% 33% !important;
    grid-template-rows: 2fr 4fr 3fr 3fr;
    grid-auto-flow:dense;
 
   
}

.topic-dropdown{
    overflow-y: scroll;
    max-height: 250px;
    background-color: #5899E2 ! important;
}
.create-section-title{
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 2.5em;
    font-family: "raleway";
    font-weight: bold;
    color: rgba(57, 87, 128 , 1);
    text-align: left;
}
.card-drop {
     width: 100%;
     margin-left: 12.5%;
     margin-right: 12.5%;
     text-align: center;

}
.create-button-top-wrapper
{
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    overflow: visible;
    text-align: right;
}
.submit-button{

    text-transform: none;
    font-size: 2.0em;
    border-width: 2px;
    background-color: #FCAB10;
    font-family:'Raleway';
    color: white;
    border-color: #FCAB10;
    border-radius: 10px;
    // font-weight: normal;
    padding-left: 1em;
    padding-right: 1em;
    // padding: 12px 10px;

    width: fit-content;
    justify-content: flex-end;
    
    // display: flex;
    margin: auto auto;
    // margin-right: 20%;
    // margin-left: auto;
    margin-top: 1em;
    margin-bottom: 2em;

}

.submit-button-top{

    text-transform: none;
    font-size: 1.8em;
    border-width: 2px;
    background-color: #FCAB10;
    font-family:'Raleway';
    color: white;
    border-color: #FCAB10;
    border-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;

    width: fit-content;
    justify-content: flex-end;
    
    // display: flex;
    margin: auto auto;
    // margin-right: 20%;
    // margin-left: auto;
    
    

}

.create-button-wrapper{
    width: 100%;

    text-align: right;
    // margin-bottom: 20em;
    padding-right: 12.5%;
    height: fit-content;


}
.create-set-plus{
    // width: 100%;

    height: fit-content;
    vertical-align: middle;
    margin: auto auto ! important;
    margin-top: 1em ! important;
    font-size: 2em;
    // margin-bottom: 1em;

}

.card-body{
    // display: grid;
    // grid-template-columns: 1fr 5fr 1fr;
    // grid-template-rows:1fr ;
    width: 100%;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    margin-bottom: 1em;
    
}
.menu-add-topic-input-wrapper{

}
.input-card{
    width:75%;
    box-shadow: 1em 1em 2em rgba(0,0,0,.2);
    border-radius: 10px;
    height: fit-content;
    min-height: 100px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: white;
    justify-content: left;
    display: grid;
    grid-template-columns: .5fr 9fr 1fr;
  

}
//bstrash
.trash-icon{
    width: 35%;
    height: 40%;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
    color: rgb(158 , 170 , 180);
    font-weight: lighter;
 

}

.invalid{
    color : red !important;
    border-width: 1px;
    border-style: solid;
    border-color: red;
}
.check-icon-card{
    width: 20px;
    height: 20px;
    margin-right:2%;
    margin-top: 10px;
    color: green;
 

}
.input-card-header{
    width: 100%;
    height: 100%;
}    
.drag-icon{
    height: 100%;
    font-size: 26pt;

}
.input-card-body{
    grid-column-start: 2;
    border-radius: 10px;
    margin-top: 2em;
    width: 95%;
    background-color: white;
    display: flex;
    justify-content: center;
    vertical-align: center;
    margin: auto auto;
    min-height: 120px;
    margin-bottom: 1em;
    padding: auto auto;
    margin-left: 4% ;
    margin-right: 0;
}
*:focus {
    outline: none;
}
.input-field{
    min-height: .5em;
    height: fit-content;
    display: flex;
    align-items: center;
    margin: auto auto;
    border-bottom: solid;
    border-top-style: none;
    border-bottom-width: 3px;
    border-bottom-color: rgb(57 , 87 , 128);
    border-radius: 2px;
    background-color: rgba(0 , 0 , 0 , 0);
    color: black;
    font-family: "OpenSans";
    word-wrap: break;
    font-size: 1.8em;
    padding-bottom: 2px;


    
    &::placeholder {
        color: rgb(196, 195, 195);
        font-style: italic;
    }

    &-term{
        max-width: 30%;
        margin-right: 2em;
        

    }   

    &-definition{
        margin-right: 0em;
        max-width: 100%;

    }


    
      
}
.section-title{
    font-size: 2em;
    padding-left: 2em;
    margin-bottom: .5em;
    margin-top: 3em;
}
.plus {

    width:60px;
    height:60px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    grid-column-start: 2;
    color: #5899E2;
    vertical-align: top;
    text-align: center;
    font-size: 16pt ;
    margin-top: 1em;

    border-color:white ;

  }
  @media (max-width:1350px){
    #set-topic-input
    {
        font-size: 1.8em;
    }
  }
  @media (max-width:1200px){
      .menu-topic-text{
          font-size: .9em !important; 
      }
      .menu-add-topic-input{
        font-size: .9em !important; 
    }
    .generate-button-top{
        font-size: 1.3em;
    }
    .trash-icon{
        width: 45%;

    }
    .menu-add-topic-plus{
        height: 20px;

    }
    .upload{
        font-size: 1.2em;
    }
    .submit-button-top{
        font-size: 1.3em;
    }
    .setname-input{
        font-size: 1.8em;
        min-width: 200px;
    }
    #set-topic-input
    {
        font-size: 1.8em;
    }
    .date-text{
        font-size: 1.2em;
    }
    .date-input{
        font-size: 1.2em;
    }
    .select-topic-description{
        font-size: 1.4em;
    }
  }
  @media (max-width:1000px){
    .menu-topic-text{
        font-size: .8em !important; 
    }
    .menu-add-topic-input{
      font-size: .8em !important; 
  }
    .menu-add-topic-plus{
        height: 15px;
        
    }
    .setname-input{
        min-width: 200px;
        font-size: 1.6em;
    }
    .input-card-body{
        min-height: 125px;
    }
    .create-set-top{
        min-height: 350px;
    }
    .generate-button-top{
        font-size: 1.1em;
    }
    .upload{
        font-size: 1.1em;
    }
    .submit-button-top{
        font-size: 1.3em;
    }
    #set-topic-input
    {
        font-size: 1.4em;
    }
    .date-text{
        font-size: 1em;
    }
    .date-input{
        font-size: 1.0em;
    }
    .select-topic-description{
        font-size: 1.3em;
    }
    .create-section-title{
        font-size: 1.8em;
    }
    .plus{
        width: 45px;
        height: 45px;
    }
    
  
  
  }

  @media (max-width:760px){
    .menu-topic-text{
        font-size: .65em !important; 
    }
    .menu-add-topic-input{
      font-size: .65em !important; 
  }
    .submit-button{
        font-size: 1.2em;
    }
    .mid-section-title{
        font-size: 1.1em;
    }
    .setname-input{
        min-width: 150px;
        font-size: 1.3em;
    }
    .menu-add-topic-plus{
        height: 13px;
        
    }
    .create-set-top{
        min-height: 300px;
    }
    .generate-button-top{
        font-size: .9em;
        padding: 10px 10px;
    }
    .upload{
        font-size: .9em;
    }
    .submit-button-top{
        font-size: .9em;
    }
    #set-topic-input
    {
        font-size: 1.1em;
    }
    .date-text{
        font-size: .8em;
    }
    .drag-icon{
        font-size: 24pt;
    }
    .date-input{
        font-size: .8em;
    }
    .select-topic-description{
        font-size: 1.0em;
    }
    .create-section-title{
        font-size: 1.4em;
    }
    .plus{
        width: 35px;
        height: 35px;
    }

    .input-field{
        font-size: 1em;
        // margin-top: 1em;
    }
    .input-card-body{
        min-height: 100px;
        // min-width: ;
    }
}

    @media (max-width:600px){
        .menu-topic-text{
            font-size: .65em !important; 
        }
        .menu-add-topic-input{
          font-size: .65em !important; 
      }
        .trash-icon{
            width: 55%;
            
        }
        .input-card-body{
            min-height: 90px;
            // min-width: ;
        }
        .plus{
            width: 30px;
            height: 30px;
        }
        .drag-icon{
            font-size: 20pt;
        }
        .submit-button{
            font-size: .9em;
        }
        .mid-section-title{
            font-size: .8em;
        }
        .setname-input{
            min-width: 150px;
            font-size: 1.1em;
        }
        .create-set-top{
            grid-template-rows: 1fr 2fr 2.5fr 3fr;
            min-height: 270px;
            height: 200px;
        }
        .generate-button-top{
            font-size: .8em;
            padding: 6px 6px;
        }
        .upload{
            font-size: 1.0em;
        }
        .submit-button-top{
            font-size: .9em;
            padding: 6px 6px;
        }
        #set-topic-input
        {
            font-size: .9em;
            padding: 6px 8px;
        }
        .date-text{
            font-size: .75em;
        }
        .date-input{
            font-size: .75em;
            margin-left: 0;
        }
        .create-date-wrapper{
            width: 65%;
        
        }
        .select-topic-description{
            font-size: .8em;
        }

        .create-section-title{
            font-size: 1.3em;
        }
  
        .input-field{
            font-size: .8em;
            // margin-top: 1em;
        }

    }

    @media (max-width:480px){
        .menu-add-topic-plus{
            height: 10px;
            
        }
            .submit-button{
                font-size: .8em;
            }
            .mid-section-title{
                font-size: .7em;
            }

      
            .drag-icon{
                font-size: 16pt;
            }
   
            .plus{
                width: 27px;
                height: 27px;
            }
            .setname-input{
                min-width: 100px;
                font-size: .8em;
            }
            .create-set-top{
                grid-template-rows: 1fr 2fr 2.5fr 3fr;
                min-height: 270px;
                height: 200px;
                padding-left: 2%;
            }
            .generate-button-top{
                font-size: .7em;
                padding: 3px 3px;
            }
            .upload{
                font-size: 1.0em;
            }
            .submit-button-top{
                font-size: .8em;
                padding: 6px 6px;
            }
            #set-topic-input
            {
                font-size: .7em;
                width: 50%;
                min-width: 100px;
                padding: 6px 8px;
            }
            .date-text{
                font-size: .65em;
            }
            .date-input{
                font-size: .65em;
                margin-left: 0;
            }
            .create-date-wrapper{
                width: 65%;
            
            }
            .select-topic-description{
                font-size: .7em;
            }


        .input-field{
            font-size: .7em;
            // margin-top: 1em;
        }
        .input-card-body{
            min-height: 75px;
            // min-width: ;
        }
    }

    @media (max-width:360px){
        .input-card-body{
            min-height: 50px;
            margin-bottom: 25px;
            margin-top: 10px;
            // min-width: ;
        }
        .drag-icon{
            font-size: 14pt;
        
        }
        .input-card{
            min-height: 50px;
            // margin-bottom: 25px;
            // min-width: ;
        }
        .submit-button{
            font-size: .7em;
        }
        .mid-section-title{
            font-size: .65em;
        }
        .plus{
            width: 35px;

        }
        .section-title{
            font-size: 1.2em;
        }
        .setname-input{
            min-width: 60px;
  
            padding-bottom: 1px;
            padding-left: 5px;
            padding-right: 2px;
        }
        .set-topic-input{
            // margin-left: 1em;
            padding-bottom: 3px;
            padding-top: 2px;
            font-size: .6em;
            min-width: 60px;
            padding-left: 5px;
            padding-right: 2px;
        }
        .date-text{
            width: fit-content;
            margin-left: 1em;
            font-size: .5em;
        }
        .input-field{
            font-size: .45em;
            // margin-top: 1em;
        }
    }
  
  
  
  