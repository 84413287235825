
  .card-body-display{
    height: 100%;
    -webkit-transition: height .3s ease;
}

.card-table-body{
    -webkit-transition: height 1s ease;
    transition: height 1s
}

.card-table{
    


    li {
     
        border-radius: 3px;
        padding: 25px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .card-table-header {
        background-color: #95A5A6;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
      .card-table-row {
    

        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
      }
      .col-term {
        flex-basis: 50%;
        max-width: 40%;
        word-wrap: break-word;
      }
      .col-def{

      flex-basis: 49%;

      }
}
  
  
  @media (max-width: 1000px){
   .card-table{
    .card-table-header {
        font-size: .8em;
    }
    li {
        padding: 25px 15px;
        
    }
    .card-table-row{
        font-size: .8em;
    }
}
}
@media (max-width: 760px){
    .card-table{
        .card-table-header {
            font-size: .8em;
        }
        li {
            padding: 25px 15px;
            
        }
        .card-table-row{
            font-size: .8em;
        }
    }
}
@media (max-width: 600px){
    .card-table{
        .card-table-header {
            font-size: .6em;
            vertical-align: middle;
            padding: auto auto;
        }
        li {
            padding: 25px 15px;
            
        }
        .card-table-row{
            font-size: .55em;
        }
    }
}






@media (max-width: 460px){
    .card-table{
        .card-table-header {
            font-size: .5em;
            vertical-align: middle;
            padding: auto auto;
        }
        li {
            padding: 15px 15px;
            
        }
        .card-table-row{
            font-size: .45em;
        }
    }

}

@media (max-width: 360px){
.card-table{
    .card-table-header {
        font-size: .5em;
        vertical-align: middle;
        padding: auto auto;
    }
    li {
        padding: 15px 15px;
        
    }
    .card-table-row{
        font-size: .45em;
    }
}
}