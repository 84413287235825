.set-select-grid{
    width: 76%;
    height: 100%;
    display: flex;
    flex-direction:column;
    margin-left: 12%;
    padding-top: 5%;
    
}
.view-wrapper{

}
.set-select-grid .top-row{
    width: 100%;
    display: flex;
}

.set-select-grid .top-row .header-text{
    justify-content: flex-start;
    font-size: 3em;
    font-weight: bold;
    font-family: "Raleway";
    color: rgba(57, 87, 128 , 1);
    margin: auto auto;
}

.top-row .button-spot{
    justify-content:  flex-end;
    width: fit-content;
    margin: auto auto;
    
}
.set-select-grid .top-row .add-topic-button{
    text-transform: none;
    font-size: 1.5em;
    font-family:'Raleway';
    color: white;
    border-color: #FCAB10;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    white-space: nowrap;
    width: fit-content;
}
.unassigned-sets{
    height: fit-content;
    margin-top: 2em;
}
.unassigned-sets .header-text{
    justify-content: flex-start;
    font-size: 2.5em;
    font-weight: bold;
    font-family: "Raleway";
    color: rgba(57, 87, 128 , 1);
    margin: auto auto;
}

@media (max-width:1350px){

  }
  @media (max-width:1200px){
    .set-select-grid .top-row .header-text{
        font-size: 2.5em;
    }
  }
  @media (max-width:1000px){

    
  
  
  }

  @media (max-width:760px){
    .set-select-grid .top-row .add-topic-button{
        font-size: 1.4em;
        padding: 8px 10px;
    }
    .set-select-grid  .header-text{
        font-size: 2.0em;
    }
}

    @media (max-width:600px){
        .set-select-grid .top-row .add-topic-button{
            font-size: 1.0em;
        }
        .set-select-grid  .header-text{
            font-size: 1.5em;
        }

    }

    @media (max-width:480px){
        .set-select-grid .top-row .add-topic-button{
            font-size: .8em;
            padding: 5px 7px;
        }
        .set-select-grid .header-text{
            font-size: 1.3em;
        }
    }

    @media (max-width:360px){
        
    }
  

