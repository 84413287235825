/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .CircularProgressbar {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
    width: 50%;
    /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
    vertical-align: middle;
    margin: 0 auto;

  }
  
  .CircularProgressbar .CircularProgressbar-path {
    stroke: #20c294;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    stroke-linecap: round;
  }
  
  .CircularProgressbar .CircularProgressbar-text {
    fill: #20c294;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }
  
  .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6;
  }
  
  /*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: #3e98c7;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }

  @media (max-width:1000px)
  {
    .CircularProgressbar{
      min-width: 150px;
      min-height: 150px;
      margin-left: 2em;
    }
  }

  @media (max-width:760px)
  {
    .CircularProgressbar{
      min-width: 120px;
      min-height: 120px;
    }
    .CircularProgressbar .CircularProgressbar-text{
      font-size: 1em;
    }
  }

  @media (max-width:600px)
  {
    .CircularProgressbar{
      min-width: 100px;
      min-height: 100px;
    }
    .CircularProgressbar .CircularProgressbar-text{
      font-size: 1em;
    }
  }

  @media (max-width:460px)
  {
    .CircularProgressbar{
      min-width: 70px;
      min-height: 70px;
    }
    .CircularProgressbar .CircularProgressbar-text{
      font-size: 1em;
    }
  }