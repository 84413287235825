.title-grid{
    display: flex;
    width: 100%;
    
    height: 100px;
    max-height: 125px;
    background-color: #5899E2;
    /* background-color: #49BEAA;  */
    /* background-color: #5f377d; */
    color: white;
    vertical-align: middle;
    vertical-align: middle;
    align-items: center;
    align-self: center;
    padding: auto auto;
    /* padding-top: 13px; */
    


}


.spaced{
    /* margin-bottom: 3em; */
}

.title-underline{
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    text-decoration-color: #FCAB10;
}


.title-bar-text{
    text-align: right;
    margin: auto 0;
    margin-right: 2em;
    width: fit-content;
    justify-content: flex-end;
    color: white ;
    font-size: 1.4em;
    vertical-align: middle;
    align-items: center;
    align-self: center;
    font-family: "Raleway";
}

.title-my-sets{
    /* padding-left: 1.5em; */
    vertical-align: middle;
}

.title-create-set{
    /* padding-left: 1.5em; */
    justify-content: flex-start;
    vertical-align: middle; 
}

.title-automatic-generator{
    /* padding-left: 1.5em; */
    justify-content: flex-start;
    vertical-align: middle;
}

.title-logout{
    width: fit-content;
    padding-left: 1.5em;
    vertical-align: middle;

    justify-content: flex-end;
    padding-right: 5em;
 
}

.title-contact{
    margin-right: 3em;
    background-color: #416da0;
    border-radius: .2em;
    padding: .7em .7em;
}

.title-font{
    margin: auto 2.5em;
    vertical-align: middle;
    margin-left: 5%;
    justify-content: flex-start;
    width: fit-content;
    padding-left: .5em;
    padding-right: .5em;

    
 
}
.title-font-white{
    font-size: 2.55em;
    color: white;
    font-family: "Raleway";
    font-weight: bold;
    white-space: nowrap;
}

.title-font-flashcards{
    /* margin: auto 2.5em; */
    vertical-align: middle;
    font-size: 1.8em;
 
    width: fit-content;
    color: #416da0;
    font-family: "Raleway";
    font-weight: bold;
    white-space: nowrap;
    
 
}

.links-no-auth{
    width: 100%;
    justify-content: flex-end;
    vertical-align: middle;
    align-items: center;
    /* margin: auto 0; */
    color: white;
    display: flex;

    
}

.links{
    width: 100%;
    justify-content: flex-start;
    vertical-align: middle;
    margin: auto 0;
    color: white;
    display: flex;
    
}

@media (max-width:1100px){
    .links-no-auth{
        width: 80%;
        padding-left: 0;
 
    }
   

    .title-bar-text{

        margin-right: 1em;
        font-size: 1.2em;
    }
    .title-font{
        margin-right: 1em;
        margin-left: 2em;
    }
    .title-font-white{

        font-size: 1.8em;
    }
    .title-font-flashcards{

        font-size: 1.8em;
    }

    .authed-title{
        margin-right: .8em;
        margin-left: .8em;
    }

    .links{
        /* width: 80%; */
        font-size: 1.2em;
 
    }
    .title-logout{
        padding-right: 1.8em;
    }



} 


@media (max-width:768px){
    .links-no-auth{
        width: 80%;
        padding-left: 0;
 
    }
   

    .title-bar-text{

        margin-right: 1em;
        font-size: 1.0em;
    }

    .title-font{
        margin-right: 1em;
        margin-left: 2em;
    }
    .title-font-white{
        font-size: 1.4em;
    }
    .title-font-flashcards{
        font-size: 1.4em;
    }

    .authed-title{
        margin-right: .8em;
        margin-left: .8em;
    }

    .links{
        /* width: 80%; */
        font-size: 1.0em;
 
    }
    .title-logout{
        padding-right: 1.8em;
    }



} 

@media (max-width:600px){
    .links-no-auth{
        width: 80%;
        padding-left: 0;
 
    }
   

    .title-bar-text{

        margin-right: 1em;
        font-size: .85em;
    }
    .title-font{
        margin-right: 1em;
        margin-left: 1.5em;
    }
    .title-font-white{

        font-size: 1.2em;
    }
    .title-font-flashcards{

        font-size: 1.05em;
    }

    .authed-title{
        margin-right: .8em;
        margin-left: .8em;
    }

    .links{
        /* width: 80%; */
        font-size: .8em;
 
    }
    .title-logout{
        padding-right: 1em;
        font-size: .8em;
    }
    .title-grid{
        height: 85px !important;
    }


} 


@media  (max-width:480px){
    .links-no-auth{
        width: 80%;
        padding-left: 0;
 
    }
   

    .title-bar-text{

        margin-right: 1em;
        font-size: .7em;
    }
    .title-font{
        padding-right: 0em;
        padding-left: .1em;
    }
    .title-font-white{
 
        font-size: 1.4em;

    }
    .title-font-flashcards{

        font-size: 1.2em;

    }

    .authed-title{
        margin-right: .8em;
        margin-left: .8em;
    }

    .links{
        /* width: 80%; */
        font-size: .8em;
 
    }
    .title-logout{
        padding-right: .1em;
        padding-left: .5em;
        margin-right: .15em;
        font-size: .8em;
    }
    .title-contact{
        margin-right: .3em;
        padding: .4em .4em;
    }
    .title-grid{
        height: 75px !important;
    }


} 

@media (max-width:380px){
    .links-no-auth{
        width: 80%;
        padding-left: 0;
 
    }
   

    .title-bar-text{

        margin-right: 1em;
        font-size: .57em;
    }
    .title-font{
        margin-right: 1em;
        margin-left: 1.5em;
        padding-right: 0em;
        padding-left: .1em;
    }
    .title-font-white{
        font-size: 1.0em;
        padding-bottom: .2em;

    }
    .title-font-flashcards{

        font-size: .9em;
        padding-bottom: .2em;

    }

    .authed-title{
        margin-right: .8em;
        margin-left: .8em;
    }

    .links{
        /* width: 80%; */
        font-size: .6em;
 
    }
    .title-logout{
        padding-right: .1em;
        padding-left: .5em;
        margin-right: .15em;
        font-size: .6em;
    }
    .title-grid{
        height: 70px !important;
    }


}

@media (max-width:320px){
    .links-no-auth{
        width: 80%;
        padding-left: 0;
 
    }
   

    .title-bar-text{

        margin-right: 1em;
        font-size: .55em;
    }

    .title-font-white{
        font-size: .75em;

    }
    .title-font-flashcards{

        font-size: .75em;

    }

    .title-font{
        margin-right: 1em;
        margin-left: 2em;
        padding-right: 0em;
        padding-left: .1em;
        

    }

    .authed-title{
        margin-right: .8em;
        margin-left: .8em;
    }

    .links{
        /* width: 80%; */
        font-size: .45em;
 
    }
    .title-logout{
        padding-right: .1em;
        padding-left: .5em;
        margin-right: .15em;
        font-size: .45em;
    }
    .title-grid{
        height: 60px !important;
    }


}






