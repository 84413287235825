.tutorial-popup{
    background-image: linear-gradient(to bottom,  #80b7f5,rgb(57, 87, 128 ) ); 
    background-color: rgb(57, 87, 128 );
    width: 100%;
    height: 100%;
    color: white;
}
.tutorial-popup-header{
    width: 100%;
    text-align: center;
    padding-top: 10%;
    font-size: 1.6em;
}

.tutorial-popup-list{
    width: 80%;
    // text-align: center;
    margin: auto auto;
    padding-left: 5%;
    padding-top: 5%;
}
.tutorial-button-wrapper{
 display: flex;
 justify-content:space-evenly;
 position: absolute;
 bottom: 15%;


}
.tutorial-button{
    width: 40%;
    padding-left: 0;
    padding-right: 0;
    padding: auto;
    text-transform: none;
    font-size: .9em;
    font-weight: normal;

}

@media(max-width: 700px)
{
    .tutorial-popup-header{
        font-size: 1.2em;
    }
    
    .tutorial-popup-list{
        font-size: .9em;
    }
    .tutorial-button-wrapper{
        font-size: 1em;
    
    
    }
    .tutorial-button{
        font-size: .8em;
    
    }
}
@media(max-width: 400px)
{
    .tutorial-popup-header{
        font-size: .8em;
    }
    
    .tutorial-popup-list{
        font-size: .6em;
    }
    .tutorial-button-wrapper{
        font-size: .7em;
    
    
    }
    .tutorial-button{
        font-size: .8em;
    
    }
}