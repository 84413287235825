.flip-button{
    flex:auto;
    color: white;
    background-color:  #FCAB10;
    min-width: 200px;
    max-width: 300px;
    text-align: center;
    font-family: "Glacial";
    font-size: 20pt;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: 5px;
    margin: 1em auto;
    margin-left: 1em;
}

.eval-div{
    display: flex;
    justify-content: center;
    padding-top: 1em;
    width:90%;
    text-align: center;
    margin: 0 auto;
    font-size: 16pt;


}
.set-top-bar-wrapper{
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    display: flex;
    width:100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 2em;


    text-align: center;
    margin: 0 auto;
    font-size: 16pt;

}
.flip-switch-wrapper{
    width: fit-content;
    
    padding: auto auto;
    margin: auto 0em;
    margin-right: 1em;

}
.flip-reset-wrapper{
    width: fit-content;

    padding: auto auto;
    margin: auto 1em;
    margin-right: 15%;


    
    
}
.flip-reset-button{
    font-size: 18pt;
    margin: auto 0;
    padding: auto;
}
.flip-switch-button{
    font-size: 25pt;
    margin: auto auto;
    
}
.flip-menu-button{
    
    color: white;
    background-color:  #FCAB10;
    width: 20%;
    text-align: center;
    font-family: "Glacial";
    font-size: 20pt;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: 5px;
    // margin: 1em auto;
    margin-left: 1em;

    // justify-content: f;

}
@media (max-width: 1400px){
    .flip-button{

        font-size: 15pt;
        padding-right: .15em;
        padding-left: .15em;
        min-width: 100px;
        max-width: 200px;
        margin-left: 1em;
    
    }
    .flip-reset-button{
        font-size: 15pt;
    }
    .flip-switch-button{
        font-size: 20pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .5em;
    
    }
    .flip-reset-wrapper{

   
        
        
    }
}

@media (max-width: 1200px){
    .flip-button{

        font-size: 15pt;
        padding-right: .15em;
        padding-left: .15em;
        min-width: 100px;
        max-width: 130px;
        margin-left: 1em;
    
    }
    .flip-reset-button{
        font-size: 15pt;
    }
    .flip-switch-button{
        font-size: 20pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .5em;
    
    }
    .flip-reset-wrapper{

   
        
        
    }
}

@media (max-width: 1000px){

    .flip-reset-button{
        font-size: 13pt;
    }
    .flip-switch-button{
        font-size: 17pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .3em;
    
    }
    .flip-reset-wrapper{

   
        
        
    }
    .flip-button{

        font-size: 15pt;
        padding-right: .15em;
        padding-left: .15em;
        min-width: 100px;
        max-width: 100px;
        margin-left: 1em;
    
    }
}
@media (max-width: 800px){
    .flip-button{

        font-size: 10pt;
        padding-right: .1em;
        padding-left: .1em;
        min-width: 80px;
        max-width: 80px;
        margin-left: 1em;
    
    }
    .flip-reset-button{
        font-size: 13pt;
    }
    .flip-switch-button{
        font-size: 17pt;
        
    }
    .flip-switch-wrapper{

        margin-right: .1em;
    }
    .flip-reset-wrapper{

        margin-left: 1em;
        margin-right: 1em;
        
        
    }
    
}
@media (max-width: 680px){
    .flip-button{

        font-size: 10pt;
        padding-right: .7em;
        padding-left: .7em;
        min-width: 70px;
        max-width: 70px;
        margin-left: .5em;
    
    }
}
@media (max-width: 550px){
    .flip-button{

        font-size: 9pt;
        padding-right: .7em;
        padding-left: .7em;
        min-width: 60px;
        max-width: 60px;
        margin-left: .5em;
    
    }
}

